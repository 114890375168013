import { Fragment } from 'react';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { UserFederation } from '../../../integration.model';
import { useTranslation } from 'react-i18next';

interface IUserFederationLdapPanel {
  userFederation: UserFederation;
}

export const UserFederationLdapPanel = ({ userFederation }: IUserFederationLdapPanel) => {
  const { t } = useTranslation();
  const { connection } = userFederation.attributes;
  const { usersDn, usernameLdapAttribute, rdnLdapAttribute, uuidLdapAttribute, userObjectClasses, searchScope } =
    connection;

  const info = [
    { label: t('integrations.users_dn.label'), value: usersDn, description: t('integrations.users_dn.description') },
    {
      label: t('integrations.username_attribute.label'),
      value: usernameLdapAttribute,
      description: t('integrations.username_attribute.description'),
    },
    {
      label: t('integrations.rdn_attribute.label'),
      value: rdnLdapAttribute,
      description: t('integrations.rdn_attribute.description'),
    },
    {
      label: t('integrations.uuid_attribute.label'),
      value: uuidLdapAttribute,
      description: t('integrations.uuid_attribute.description'),
    },
    {
      label: t('integrations.object_classes.label'),
      value: userObjectClasses,
      description: t('integrations.object_classes.description'),
    },
    {
      label: t('integrations.search_scope.label'),
      value: searchScope === '2' ? t('integrations.search_scope.subtree') : t('integrations.search_scope.one_level'),
      description: t('integrations.search_scope.description'),
    },
  ];

  return (
    <div>
      <InfoGrid>
        {info.map(({ label, value }) => (
          <Fragment key={label}>
            <InfoGrid.Label>{label}</InfoGrid.Label>
            <InfoGrid.Value>{value}</InfoGrid.Value>
          </Fragment>
        ))}
      </InfoGrid>
    </div>
  );
};
