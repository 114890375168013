import { IUserDetailView } from '../UserDetailView';
import { InputField, useForm } from '@usu/react-form';
import { FlexRow } from '../../../../../component/common/layout/Flex';
import { UsuBannerMessage, UsuButton, UsuIcon } from '@usu/react-components';
import { useTranslation } from 'react-i18next';
import { useUpdateUserMutation } from '../../../user.mutations';
import { UpdateUserBody } from '../../../user.model';

interface IUserAttributesPanel extends IUserDetailView {}

interface IForm {
  attributes?: { key: string; value: string }[];
}

export const UserAttributesPanel = ({ user }: IUserAttributesPanel) => {
  const { t } = useTranslation();
  const { attributes } = user.attributes;
  const form = useForm<IForm>({
    initialValues: {
      attributes: Object.entries(attributes || {}).map(([key, value]) => ({ key, value: value.join('; ') })),
    },
  });
  const userMutation = useUpdateUserMutation(form);

  const onSubmit = (values: IForm) => {
    const attributes: UpdateUserBody['attributes'] = {};
    values.attributes?.forEach(({ key, value }) => (attributes[key] = value.split(';').map((v) => v.trim())));
    const payload = {
      username: user.attributes.username,
      email: user.attributes.email,
      firstname: user.attributes.firstname || '',
      lastname: user.attributes.lastname || '',
      attributes,
    };
    userMutation.mutate({ userId: user.id, ...payload });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <UsuBannerMessage hideCloseButton className={'mg-b-nm'}>
        {t('general.string_list_semicolon_as_separator')}
      </UsuBannerMessage>
      {form.values.attributes?.map((attribute, index) => {
        return (
          <FlexRow key={`attribute-${index}`} className={'mg-b-nm'} alignItems={'center'}>
            <InputField
              label={index === 0 && t('general.key')}
              required
              className={'mg-r-sm'}
              style={{ width: '50%' }}
              {...form.getInputProps(`attributes.${index}.key`)}
            />
            <InputField
              label={index === 0 && t('general.value')}
              className={'mg-r-sm'}
              style={{ width: '50%' }}
              {...form.getInputProps(`attributes.${index}.value`)}
            />
            <UsuButton
              className={index === 0 ? 'mg-t-nm' : ''}
              shape={'circle'}
              color={'tertiary'}
              onClick={() => form.removeListItem('attributes', index)}
            >
              <UsuIcon slot={'icon-before'} type={'delete-circle'} />
            </UsuButton>
          </FlexRow>
        );
      })}
      <UsuButton
        color={'secondary'}
        size={'small'}
        onClick={() => form.insertListItem('attributes', { key: '', value: '' })}
      >
        <UsuIcon slot={'icon-before'} type={'add'} />
        {t('general.add_attribute')}
      </UsuButton>

      <FlexRow className={'mg-t-xl'}>
        <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
          {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
          {t('general.save')}
        </UsuButton>
      </FlexRow>
    </form>
  );
};
