import { ApiMutationFn, useApiMutation } from '../../support/api';
import { HttpResponse } from '../../support/http';
import { API_PATHS } from '../../config/paths';
import { USERS_QUERY_KEY } from './user.queries';
import { UseFormReturnType } from '@usu/react-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useUsuToast } from '@usu/react-components';
import { CreateUserBody, UpdateUserBody, UserEmailAction, UserRequiredAction } from './user.model';

const createUserMutation: ApiMutationFn<HttpResponse<any>, CreateUserBody> = (http, payload) =>
  http.post<any>(API_PATHS.user.create, payload);

export const useCreateUserMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(createUserMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('users.create.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('users.create.error'),
      });
    },
  });
};

const updateUserMutation: ApiMutationFn<HttpResponse<any>, { userId: string } & UpdateUserBody> = (
  http,
  { userId, ...payload }
) => http.put<any>(API_PATHS.user.single(userId), payload);

export const useUpdateUserMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(updateUserMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('users.update.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('users.update.error'),
      });
    },
  });
};

const deleteUserMutation: ApiMutationFn<HttpResponse<any>, { userId: string }> = (http, { userId }) =>
  http.delete<any>(API_PATHS.user.single(userId));

export const useDeleteUserMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(deleteUserMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: t('users.delete.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.delete.error'),
      });
    },
  });
};

const enableUserMutation: ApiMutationFn<HttpResponse<any>, { userId: string }> = (http, { userId }) =>
  http.patch<any>(API_PATHS.user.enable(userId));
const disableUserMutation: ApiMutationFn<HttpResponse<any>, { userId: string }> = (http, { userId }) =>
  http.patch<any>(API_PATHS.user.disable(userId));

export const useEnableOrDisableUserMutation = (enable: boolean) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(enable ? enableUserMutation : disableUserMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: enable ? t('users.enable.success') : t('users.disable.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: enable ? t('users.enable.error') : t('users.disable.error'),
      });
    },
  });
};

const setRequiredUserActionsMutation: ApiMutationFn<
  HttpResponse<any>,
  { userId: string; actions: UserRequiredAction[] }
> = (http, { userId, ...payload }) => http.put<any>(API_PATHS.user.requiredActions(userId), payload);

export const useSetRequiredUserActionsMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(setRequiredUserActionsMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: t('users.required_actions.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.required_actions.error'),
      });
    },
  });
};

const setUserEmailActionsMutation: ApiMutationFn<HttpResponse<any>, { userId: string; actions: UserEmailAction[] }> = (
  http,
  { userId, ...payload }
) => http.put<any>(API_PATHS.user.emailActions(userId), payload);

export const useSetUserEmailActionsMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(setUserEmailActionsMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: t('users.email_actions.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.email_actions.error'),
      });
    },
  });
};

const setUserPasswordMutation: ApiMutationFn<
  HttpResponse<any>,
  { userId: string; password: string; temporary: boolean }
> = (http, { userId, ...payload }) => http.put<any>(API_PATHS.user.password(userId), payload);

export const useSetUserPasswordMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(setUserPasswordMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: t('users.password.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.password.error'),
      });
    },
  });
};

const assignClientRoleToUserMutation: ApiMutationFn<
  HttpResponse<any>,
  { userId: string; clientId: string; roleName: string }
> = (http, { userId, clientId, roleName }) => http.post<any>(API_PATHS.user.role.single(userId, clientId, roleName));

export const useAssignClientRoleToUserMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(assignClientRoleToUserMutation, {
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY, userId, 'roles'] }).catch();
      toast.show({
        status: 'success',
        header: t('users.assign_role.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.assign_role.error'),
      });
    },
  });
};

const unassignClientRoleFromUserMutation: ApiMutationFn<
  HttpResponse<any>,
  { userId: string; clientId: string; roleName: string }
> = (http, { userId, clientId, roleName }) => http.delete<any>(API_PATHS.user.role.single(userId, clientId, roleName));

export const useUnassignClientRoleFromUserMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(unassignClientRoleFromUserMutation, {
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY, userId, 'roles'] }).catch();
      toast.show({
        status: 'success',
        header: t('users.unassign_role.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.unassign_role.error'),
      });
    },
  });
};
