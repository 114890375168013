import { TreeNodeData } from '../../component/common/misc/tree/Tree';
import { Group } from './group.model';
import { isEmpty } from '../../support/util';

export const generateTree = (data: Group[]): TreeNodeData[] => {
  const transformGroupTree = (group: Group): TreeNodeData => {
    const { name, subgroups } = group.attributes;
    return {
      label: name,
      value: group.id,
      ...(!isEmpty(subgroups)
        ? {
            children: subgroups.map(transformGroupTree),
          }
        : {}),
    };
  };
  return data.map(transformGroupTree);
};

export const findGroupById = (data: Group | Group[], groupId: string): Group | null => {
  if (Array.isArray(data)) {
    for (const group of data) {
      const result = findGroupById(group, groupId);
      if (result) {
        return result;
      }
    }
  } else if (data.id === groupId) {
    return data;
  } else if (data.attributes.subgroups && data.attributes.subgroups.length > 0) {
    for (const child of data.attributes.subgroups) {
      const result = findGroupById(child, groupId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};
