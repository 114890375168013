import {
  ApiPage,
  getQueryParamsFromKey,
  normalizePagedQueryParams,
  useApiQuery,
  usePagedApiQuery,
} from '../../support/api';
import { API_PATHS } from '../../config/paths';
import { ApiUserFederationData, UserFederation } from './integration.model';

export const USER_FEDERATIONS_QUERY_KEY = 'user-federations';

export const useUserFederationsQuery = () => {
  return usePagedApiQuery((params) => [USER_FEDERATIONS_QUERY_KEY, normalizePagedQueryParams(params)], {
    queryFn: (http, ctx) => {
      const params = getQueryParamsFromKey(ctx.queryKey);
      return http
        .get<ApiPage<UserFederation>>(API_PATHS.integration.userFederation.list, { signal: ctx.signal, params })
        .then((res) => res.data);
    },
  });
};

export const useUserFederationQuery = (id: undefined | string) => {
  return useApiQuery([USER_FEDERATIONS_QUERY_KEY, id], {
    queryFn: (http, ctx) =>
      http
        // get is only performed if 'id' is string
        .get<ApiUserFederationData>(API_PATHS.integration.userFederation.single(id!), { signal: ctx.signal })
        .then((res) => res.data),
    enabled: !!id,
  });
};
