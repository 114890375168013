import { ApiEntityData } from '../../support/api';

export enum GroupType {
  Group = 'group',
  Global = 'global',
  App = 'app',
}

export interface Group {
  id: string;
  type: 'group';
  attributes: {
    path: string;
    subgroups: Group[];
    clients: string[];
    roles: string[];
    name: string;
    attributes: Record<'isGlobal' | 'description' | string, string[]>;
    directMembership: boolean;
  };
}

export interface GroupRequestBody {
  name?: string;
  attributes?: Record<string, string[]>;
}

export interface ApiGroupData extends ApiEntityData<Group[]> {}
