import { UsuAvatar, UsuListItem } from '@usu/react-components';
import { getAccountInitials, getAccountName } from '../../account/account.util';
import { User } from '../user.model';
import { UserStateTag } from './UserStateTag';
import { getListItemStyle } from '../../../component/common/misc/MasterDetailView';

interface IUsersListItem {
  user: User;
  selected?: boolean;
  onClick?: () => void;
}

export const UserListItem = ({ user, selected, onClick }: IUsersListItem) => {
  const { email, profilePictureUrl } = user.attributes;

  return (
    <UsuListItem onClick={onClick} selected={selected} style={getListItemStyle(selected)}>
      <UsuAvatar slot='avatar' initials={getAccountInitials(user)} image={profilePictureUrl} />
      <span slot='title'>{getAccountName(user)}</span>
      <UserStateTag user={user} />
      <span>{email}</span>
    </UsuListItem>
  );
};
