import { Client } from '../../client.model';
import { ClientHeaderInfo } from './component/ClientHeaderInfo';
import { UsuTab, UsuTabBar, UsuTabPanel } from '@usu/react-components';
import { ClientDetailsPanel } from './component/ClientDetailsPanel';
import { ClientRolesPanel } from './component/ClientRolesPanel';
import { useTranslation } from 'react-i18next';

interface IClientDetailView {
  client: Client;
}

export const ClientDetailView = ({ client }: IClientDetailView) => {
  const { t } = useTranslation();
  return (
    <div>
      <ClientHeaderInfo client={client} />
      <UsuTabBar className={'pd-t-nm'}>
        {/* client details */}
        <UsuTab slot='header' name={'details'}>
          {t('general.details')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'details'} className={'pd-t-nm'}>
          <ClientDetailsPanel client={client} />
        </UsuTabPanel>

        {/* client roles */}
        <UsuTab slot='header' name={'roles'}>
          {t('general.roles')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'roles'} className={'pd-t-nm'}>
          <ClientRolesPanel client={client} />
        </UsuTabPanel>
      </UsuTabBar>
    </div>
  );
};
