import { UsuSidebar, UsuSidebarItem, UsuSidebarSubItem } from '@usu/react-components';
import { pages, useNav } from '../../../config/routes';
import { isNil } from '../../../support/util';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function LayoutSidebar() {
  const nav = useNav();
  const { t } = useTranslation();
  const matches = useMatches();

  return (
    <UsuSidebar slot={'sidebar'}>
      {pages.map((route) => {
        const isActive = matches.some((match) => match.id === route.id);

        return (
          <UsuSidebarItem
            key={route.id}
            label={t(route.menu!.title) as string}
            icon={route.menu!.icon as any}
            active={isActive}
            onClick={() => nav({ id: route.id })}
          >
            {isNil(route.children)
              ? null
              : route.children.map((child) => {
                  const id = `${route.id}.${child.id}`;
                  const isActive = matches.some((match) => match.id === route.id);

                  return (
                    <UsuSidebarSubItem
                      key={id}
                      label={t(child.menu!.title) as string}
                      icon={child.menu!.icon as any}
                      active={isActive}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        nav({ id: child.id });
                      }}
                    />
                  );
                })}
          </UsuSidebarItem>
        );
      })}
    </UsuSidebar>
  );
}
