import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { useGroupsQuery } from '../group.queries';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tree, TreeNodeData } from '../../../component/common/misc/tree/Tree';
import { Group } from '../group.model';
import { findGroupById, generateTree } from '../group.util';
import { usePrevious } from '@usu/react-ui';
import { deepEqual } from 'fast-equals';
import { FlexRow } from '../../../component/common/layout/Flex';
import { UsuButton, UsuIcon, UsuSplitView, UsuTooltip } from '@usu/react-components';
import { useTranslation } from 'react-i18next';
import { GroupDetailView } from './detail-view/GroupDetailView';
import { CreateGroupDrawer } from './CreateGroupDrawer';
import { useDrawer } from '../../../support/hooks';
import classNames from 'classnames';
import { getListItemStyle } from '../../../component/common/misc/MasterDetailView';
import { useTree, UseTreeReturnType } from '../../../component/common/misc/tree/useTree';

export const GroupsList = () => {
  const groupQuery = useGroupsQuery();

  return (
    <QueryGuard query={groupQuery}>
      {{
        success: ({ query }) => {
          return <GroupMasterDetailView groups={query.data?.data || []} />;
        },
      }}
    </QueryGuard>
  );
};

interface IGroupMasterDetailView {
  groups: Group[];
}

const GroupMasterDetailView = ({ groups }: IGroupMasterDetailView) => {
  const { t } = useTranslation();
  const [canCollapse, setCanCollapse] = useState(false);
  const [selected, setSelected] = useState<undefined | Group>(groups[0]);
  const [present, dismiss] = useDrawer(CreateGroupDrawer, { dismiss: () => dismiss() });
  const tree = useTree();

  useEffect(() => {
    setCanCollapse(Object.values(tree.expandedState).find((expanded) => expanded) || false);
  }, [tree.expandedState]);

  const onExpandOrCollapseAll = () => (canCollapse ? tree.collapseAllNodes() : tree.expandAllNodes());

  return (
    <UsuSplitView percentage={30} leftMinPx={350} rightMinPx={400} className={'pd-b-md'} style={{ overflow: 'hidden' }}>
      <aside
        slot={'left'}
        className={'pd-r-nm'}
        style={{
          overflow: 'hidden',
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <FlexRow className={'mg-b-nm'} justifyContent={'space-between'}>
          <UsuButton size={'small'} onClick={present}>
            <UsuIcon slot={'icon-before'} type={'add'} />
            {t('groups.create.label')}
          </UsuButton>
          <UsuTooltip>
            <UsuButton color={'secondary'} size={'small'} onClick={onExpandOrCollapseAll}>
              <UsuIcon type={canCollapse ? 'collapse-all' : 'expand-all'} />
            </UsuButton>
            <span slot={'message'}>{canCollapse ? t('general.collapse_all') : t('general.expand_all')}</span>
          </UsuTooltip>
        </FlexRow>
        <section style={{ overflow: 'hidden auto' }}>
          <GroupTree tree={tree} data={groups} selected={selected} setSelected={setSelected} />
        </section>
      </aside>
      <main
        slot={'right'}
        className={'pd-l-nm'}
        style={{
          overflow: 'hidden',
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto',
        }}
      >
        {selected && <GroupDetailView key={selected.id} group={selected} />}
      </main>
    </UsuSplitView>
  );
};

interface IGroupTree {
  tree: UseTreeReturnType;
  data: Group[];
  selected?: Group;
  setSelected: Dispatch<SetStateAction<undefined | Group>>;
}

const GroupTree = ({ tree, data, selected, setSelected }: IGroupTree) => {
  const [treeData, setTreeData] = useState<TreeNodeData[]>([]);
  const prefData = usePrevious(data);

  useEffect(() => {
    if (!deepEqual(prefData, data)) {
      setTreeData(generateTree(data));
    }
  }, [data]);

  const onSelect = (groupId: string) => {
    const group = findGroupById(data, groupId);
    if (group) {
      setSelected(group);
    }
  };

  return (
    <Tree
      data={treeData}
      tree={tree}
      renderNode={({ elementProps, node, hasChildren, expanded }) => {
        const isSelectedNode = node.value === selected?.id;
        return (
          <div
            {...elementProps}
            onClick={(e) => {
              elementProps.onClick(e);
              onSelect(node.value);
            }}
          >
            <FlexRow
              className={classNames('group-item', !hasChildren && 'no-children', isSelectedNode && 'selected')}
              alignItems={'center'}
              style={getListItemStyle(isSelectedNode)}
            >
              {hasChildren ? (
                <UsuButton color={'tertiary'} shape={'circle'}>
                  <UsuIcon type={expanded ? 'angle-down' : 'angle-right'} />
                </UsuButton>
              ) : (
                <div style={{ width: '14px' }} />
              )}
              <UsuButton
                color={'tertiary'}
                onClick={(e) => {
                  elementProps.onClick(e);
                  onSelect(node.value);
                }}
              >
                {node.label}
              </UsuButton>
            </FlexRow>
          </div>
        );
      }}
    />
  );
};
