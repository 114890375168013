import { Fragment } from 'react';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { UserFederation } from '../../../integration.model';
import { useTranslation } from 'react-i18next';
import { UserFederationInfoLabel } from './UserFederationInfoLabel';

interface IUserFederationConnectionPanel {
  userFederation: UserFederation;
}

export const UserFederationConnectionPanel = ({ userFederation }: IUserFederationConnectionPanel) => {
  const { t } = useTranslation();
  const { connection } = userFederation.attributes;
  const { connectionUrl, connectionPooling, connectionTimeout, startTls, pagination, authType, bindDn } = connection;

  const info = [
    {
      label: t('integrations.connection_url.label'),
      value: connectionUrl,
      description: t('integrations.connection_url.description'),
    },
    {
      label: t('integrations.start_tls.label'),
      value: startTls ? t('general.yes') : t('general.no'),
      description: t('integrations.start_tls.description'),
    },
    {
      label: t('integrations.connection_pooling.label'),
      value: connectionPooling ? t('general.yes') : t('general.no'),
      description: t('integrations.connection_pooling.description'),
    },
    {
      label: t('integrations.connection_timeout.label'),
      value: connectionTimeout,
      description: t('integrations.connection_timeout.description'),
    },
    {
      label: t('integrations.paging_supported.label'),
      value: pagination ? t('general.yes') : t('general.no'),
      description: t('integrations.paging_supported.description'),
    },
    { label: t('integrations.auth_type.label'), value: authType, description: t('integrations.auth_type.description') },
    { label: t('integrations.bind_dn.label'), value: bindDn, description: t('integrations.bind_dn.description') },
  ];

  return (
    <div>
      <InfoGrid>
        {info.map(({ label, value, description }) => (
          <Fragment key={label}>
            <InfoGrid.Label>
              <UserFederationInfoLabel label={label} description={description} />
            </InfoGrid.Label>
            <InfoGrid.Value>{value}</InfoGrid.Value>
          </Fragment>
        ))}
      </InfoGrid>
    </div>
  );
};
