import { CSSProperties, PropsWithChildren } from 'react';
import classNames, { Argument as CSSClass } from 'classnames';

interface IInfoGrid {
  className?: CSSClass;
  style?: CSSProperties;
}

export function InfoGrid(props: PropsWithChildren<IInfoGrid>) {
  return (
    <div className={classNames('info-grid', props.className)} style={props.style}>
      {props.children}
    </div>
  );
}

interface IInfoGridLabel {}

InfoGrid.Label = function (props: PropsWithChildren<IInfoGridLabel>) {
  return <div className='info-grid__label'>{props.children}</div>;
};

// @ts-ignore
InfoGrid.Label.displayName = 'InfoGridLabel';

interface IInfoGridValue {}

InfoGrid.Value = function (props: PropsWithChildren<IInfoGridValue>) {
  return <div className='info-grid__value'>{props.children}</div>;
};

// @ts-ignore
InfoGrid.Value.displayName = 'InfoGridValue';
