import packageJson from '../../package.json';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const ENV = (IS_PRODUCTION ? window.__env__ : process.env) as unknown as Env;

/**
 * Variables read from the project's `package.json` for informational purposes,
 * i.e. request identification
 */
export const PKG = packageJson as unknown as Pkg;

/**
 * Runtime host URL of the application
 */
export const HOST_URL = `${location.protocol}//${location.host}`;

export const LEGAL_NOTICE_URL = 'https://www.usu.com/en-us/legal-notice/';
export const DATA_PRIVACY_URL = 'https://www.usu.com/en-us/data-protection-policy/';

export interface Env {
  NODE_ENV: 'development' | 'production';
  BASENAME: string;
  API_URL: string;
  API_PROXY_URL: string;
  // keycloak relevant info
  KEYCLOAK_URL: string;
  REALM: string;
  DEFAULT_IDP_HINT?: string;
  UM_ACCOUNT_URL?: string;
}

export interface Pkg {
  name: string;
  version: string;
}

declare global {
  interface Window {
    __env__: Env;
  }
}
