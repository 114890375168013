import { CSSProperties, PropsWithChildren } from 'react';

export enum Heading {
  h3,
  h4,
}

interface ISection {
  title: string;
  heading?: Heading;
  className?: string;
  style?: CSSProperties;
}

export const Section = ({ title, children, heading, ...rest }: PropsWithChildren<ISection>) => {
  const h = heading || Heading.h3;
  return (
    <div {...rest}>
      {h === Heading.h3 ? (
        <h3 className={'mg-b-sm'}>{title}</h3>
      ) : (
        <h4 className={'with_border_bottom mg-b-sm'}>{title}</h4>
      )}
      {children}
    </div>
  );
};
