import { ApiEntityData } from '../../support/api';

export enum SyncAction {
  All = 'SYNC_ALL_USERS',
  Changed = 'SYNC_CHANGED_USERS',
}

export interface UserFederation {
  id: string;
  type: 'User-Federation';
  attributes: {
    providerId: string;
    name: string;
    enabled: boolean;
    synchronization: {
      validatePasswordPolicy: boolean;
      fullSyncPeriod: number;
      changedSyncPeriod: number;
      importEnabled: boolean;
      syncRegistrations: boolean;
      batchSizeForSync: null | number;
    };
    connection: {
      vendor: string;
      startTls: boolean;
      connectionPooling: boolean;
      pagination: boolean;
      cachingPolicy: 'DEFAULT' | 'EVICT_DAILY' | 'EVICT_WEEKLY' | 'MAX_LIFESPAN' | 'NO_CACHE';
      usersDn: string;
      bindDn: string;
      usernameLdapAttribute: string;
      uuidLdapAttribute: string;
      connectionUrl: string;
      authType: 'simple' | 'none';
      connectionTimeout: null | number;
      searchScope: null | string;
      userObjectClasses: string;
      rdnLdapAttribute: string;
      editMode: 'READ_ONLY' | 'WRITABLE' | 'UNSYNCED';
      trustEmail: boolean;
      passwordModifyExtendedOperationEnabled: boolean;
      status: 'connected' | 'disconnected';
    };
    kerberos: {
      allowKerberosAuthentication: boolean;
      principalAttribute: string;
      keyTab: null | string;
      realm: null | string;
      principal: null | string;
      useKerberosForPasswordAuthentication: boolean;
    };
  };
}

export interface ApiUserFederationsData extends ApiEntityData<UserFederation[]> {}
export interface ApiUserFederationData extends ApiEntityData<UserFederation> {}
