import { useMemo } from 'react';
import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';
import { useAuth } from 'oidc-react';
import { httpConfig } from '../config/http';
import { IS_PRODUCTION } from './env';
import { isNil } from './util';

/**
 * Some type overwrites to have cleaner imports throughout the codebase
 */
export type HttpRequest<Body = unknown> = AxiosRequestConfig<Body>;
export type HttpResponse<Body = unknown> = AxiosResponse<Body>;
export type HttpError<Body = unknown> = AxiosError<Body>;
export type HttpProgressEvent = AxiosProgressEvent;

/**
 * Hook to access a HTTP client (`axios`) that's primed with the current JWT to use for API requests.
 * Note that this creates a new client instance per callsite. This is to bundle shared configuration
 * and behavior here, and deter future development from customizing the returned instance.
 */
export function useAuthenticatedHttpClient() {
  const auth = useAuth();
  const accessToken = useMemo(() => auth.userData?.access_token || null, [auth.userData?.access_token]);
  const idToken = useMemo(() => auth.userData?.id_token || undefined, [auth.userData?.id_token]);

  return useMemo(() => {
    const client = createHttpClient(httpConfig);

    client.interceptors.request.use((request) =>
      isNil(accessToken) ? request : authenticateHttpRequest(request, accessToken)
    );

    client.interceptors.response.use(
      undefined,
      (error) => {
        if (isHttpError(error) && error.response?.status === 401) {
          return auth.userManager.signoutRedirect({
            id_token_hint: idToken,
            post_logout_redirect_uri: window.location.href,
          });
        } else {
          return Promise.reject(error);
        }
      },
      {
        runWhen: isAuthenticatedHttpRequest,
      }
    );

    return client;
  }, [accessToken, idToken]);
}

function createHttpClient(config?: CreateAxiosDefaults) {
  return axios.create({
    ...config,
    headers: {
      ...config?.headers,
      // Add headers to identify frontend package name and version (for telemetry purposes)
      // 'X-Client-Name': PKG.name,
      // 'X-Client-Version': PKG.version,
    },
  });
}

const AUTH_HEADER_NAME = 'Authorization';
const AUTH_HEADER_PREFIX = 'Bearer ';

const SEEN_TOKENS = (() => {
  console.debug('Creating new seen tokens set');
  return new Set<string>();
})();

function authenticateHttpRequest(config: InternalAxiosRequestConfig, token: string): InternalAxiosRequestConfig {
  if (!IS_PRODUCTION && !SEEN_TOKENS.has(token)) {
    SEEN_TOKENS.add(token);
    // TODO: dev code; remove before release
    console.debug('%cUsing new JWT token for authentication:', 'color:darkorange;', { token });
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      [AUTH_HEADER_NAME]: `${AUTH_HEADER_PREFIX}${token.trim()}`,
    } as AxiosRequestHeaders,
  };
}

function isAuthenticatedHttpRequest(config: InternalAxiosRequestConfig) {
  const headerValue = config.headers[AUTH_HEADER_NAME] || config.headers[AUTH_HEADER_NAME.toLowerCase()];
  return headerValue.startsWith(AUTH_HEADER_PREFIX) && headerValue.length > AUTH_HEADER_PREFIX.length;
}

export function isHttpError<Body = unknown>(x?: any): x is HttpError<Body> {
  return !isNil(x) && x instanceof AxiosError;
}
