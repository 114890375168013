import { useTranslation } from 'react-i18next';
import { FileField, InputField, SelectField, ToggleField, useForm } from '@usu/react-form';
import { UsuButton, UsuButtonGroup, UsuIcon, UsuSectionMessage, UsuSelectOption } from '@usu/react-components';
import { FlexRow } from '../../../component/common/layout/Flex';
import { transformFileToJson } from '../import.util';
import { ImportFileContent, ImportLegacyFileContent, ImportType } from '../import.model';
import { useImportLegacyMutation, useImportMutation } from '../import.mutations';
import { useClientsQuery } from '../../client/client.queries';

interface IImportDrawer {
  dismiss(): void;
}

interface IFrom {
  legacy: boolean;
  file: Blob[];
  // regular
  type: ImportType;

  // legacy
  clientId: string;
  password: string;
  temporary: boolean;
}

export const ImportDrawer = ({ dismiss }: IImportDrawer) => {
  const { t } = useTranslation();
  const clientsQuery = useClientsQuery({ size: 100 });
  const form = useForm<IFrom>({
    initialValues: { legacy: false, file: [], type: ImportType.Skip, clientId: '', password: '', temporary: true },
  });
  const importMutation = useImportMutation();
  const importLegacyMutation = useImportLegacyMutation();

  const onSubmit = async ({ legacy, ...values }: IFrom) => {
    if (!legacy) {
      const { type } = values;
      const content = await transformFileToJson<ImportFileContent>(form.values.file[0]);
      importMutation.mutate({ type, ...content }, { onSuccess: dismiss });
    } else {
      const { clientId, password, temporary } = values;
      const content = await transformFileToJson<ImportLegacyFileContent>(form.values.file[0]);
      importLegacyMutation.mutate({ clientId, password, temporary, ...content }, { onSuccess: dismiss });
    }
  };

  return (
    <>
      <h2>{t('general.import')}</h2>
      <div className={'mg-t-nm'} style={{ width: '500px' }}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <ToggleField label={t('general.import_legacy')} {...form.getInputProps('legacy', { type: 'toggle' })} />
          <UsuSectionMessage hideCloseButton className={'mg-t-nm'}>
            <span slot={'title'}>
              {!form.values.legacy ? t('import.form.help.title') : t('import.form.help.legacy.title')}
            </span>
            <span>{!form.values.legacy ? t('import.form.help.message') : t('import.form.help.legacy.message')}</span>
          </UsuSectionMessage>
          <FileField
            className={'mg-t-nm'}
            accept={'application/json'}
            {...form.getInputProps('file', { eventName: 'onUsuFileUpload' })}
          />
          {!form.values.legacy ? (
            <SelectField
              className={'mg-t-nm'}
              label={'If a resource already exists, specify what should be done:'}
              {...form.getInputProps('type')}
            >
              <UsuSelectOption value={ImportType.Skip}>{t('general.skip')}</UsuSelectOption>
              <UsuSelectOption value={ImportType.Overwrite}>{t('general.overwrite')}</UsuSelectOption>
            </SelectField>
          ) : (
            <>
              <SelectField
                className={'mg-t-nm'}
                required
                label={t('general.client')}
                help={t('import.form.legacy.client.help')}
                {...form.getInputProps('clientId')}
              >
                {(clientsQuery?.[0].data?.data || []).map(({ id, attributes }) => (
                  <UsuSelectOption key={id} value={id}>
                    {attributes.name}
                  </UsuSelectOption>
                ))}
              </SelectField>
              <InputField
                className={'mg-t-nm'}
                required
                label={t('general.password')}
                help={t('import.form.legacy.password.help')}
                type={'password'}
                {...form.getInputProps('password')}
              />
              <ToggleField
                className={'mg-t-nm'}
                label={t('general.temporary_password')}
                {...form.getInputProps('temporary', { type: 'toggle' })}
              />
            </>
          )}
          <FlexRow justifyContent={'flex-end'} className={'mg-t-xl'}>
            <UsuButtonGroup>
              <UsuButton onClick={dismiss} color={'secondary'}>
                {t('general.close')}
              </UsuButton>
              <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
                {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
                {t('general.import')}
              </UsuButton>
            </UsuButtonGroup>
          </FlexRow>
        </form>
      </div>
    </>
  );
};
