import { Group } from '../../../group.model';
import { useGroupMembersQuery } from '../../../group.queries';
import { QueryGuard } from '../../../../../component/common/api/QueryGuard';
import { UsuButton } from '@usu/react-components';
import { autoTableColumns, ConfirmationAction, Table } from '@usu/react-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GROUP_MEMBERS_TABLE_COLUMNS } from '../../../group.config';
import { useRemoveUserFromGroupMutation } from '../../../group.mutations';

interface IGroupMembersPanel {
  group: Group;
}

export const GroupMembersPanel = ({ group }: IGroupMembersPanel) => {
  const { t } = useTranslation();
  const membersQuery = useGroupMembersQuery(group.id);
  const removeUserFormGroupMutation = useRemoveUserFromGroupMutation();

  const columns = useMemo(
    () =>
      autoTableColumns({
        // @ts-ignore
        columns: GROUP_MEMBERS_TABLE_COLUMNS(t),
        actions: {
          actions: {
            cellMutator: (d) => (
              <ConfirmationAction
                title={t('users.remove_group.confirm.title')}
                message={t('users.remove_group.confirm.message')}
                onConfirm={() => removeUserFormGroupMutation.mutate({ groupId: group.id, userId: d.id })}
              >
                {({ open }) => (
                  <UsuButton color={'tertiary'} onClick={open}>
                    {t('general.leave')}
                  </UsuButton>
                )}
              </ConfirmationAction>
            ),
          },
        },
      }),
    [t, group]
  );

  return (
    <QueryGuard query={membersQuery}>
      {{
        success: ({ query }) => {
          return <Table id={`group-${group.id}-members`} columns={columns} data={query.data?.data} />;
        },
      }}
    </QueryGuard>
  );
};
