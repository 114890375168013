import { AccountContentData } from '../account/account.model';

export enum UserRequiredAction {
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
}

export enum UserEmailAction {
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export interface CreateUserBody {
  username: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  attributes?: Record<string, string[]>;
}

export interface UpdateUserBody extends Required<CreateUserBody> {}

export interface User extends AccountContentData {}
