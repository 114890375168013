import { ApiMutationFn, useApiMutation } from '../../support/api';
import { HttpResponse } from '../../support/http';
import { API_PATHS } from '../../config/paths';
import { UseFormReturnType } from '@usu/react-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useUsuToast } from '@usu/react-components';

const updateSupportedLocalesMutation: ApiMutationFn<HttpResponse<any>, string[]> = (http, payload) =>
  http.post<any>(API_PATHS.realm.locales.supported, payload);

export const useUpdateSupportedLocalesMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(updateSupportedLocalesMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['realm', 'locales', 'supported'] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('realm.locales.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('realm.locales.error'),
      });
    },
  });
};
