import { Client } from '../../../client.model';
import { FlexCol, FlexRow } from '../../../../../component/common/layout/Flex';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IClientHeaderInfo {
  client: Client;
}

export const ClientHeaderInfo = ({ client }: IClientHeaderInfo) => {
  const { t } = useTranslation();
  const { translatedName, name, description, type } = client.attributes;

  const info = [
    { label: t('general.description'), value: description, classes: !description && 'no-data-text' },
    { label: t('general.type'), value: type },
  ];

  return (
    <FlexRow alignItems={'center'}>
      <FlexCol>
        <FlexRow alignItems={'center'}>
          <h2>{translatedName || name}</h2>
        </FlexRow>
        <InfoGrid>
          {info.map(({ label, value, classes }) => (
            <Fragment key={label}>
              <InfoGrid.Label>{label}:</InfoGrid.Label>
              <InfoGrid.Value>
                <span className={classNames(classes)}>{value || t('general.not_set')}</span>
              </InfoGrid.Value>
            </Fragment>
          ))}
        </InfoGrid>
      </FlexCol>
    </FlexRow>
  );
};
