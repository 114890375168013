import { useUserAvailableGroupsQuery } from '../user.queries';
import { User } from '../user.model';
import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { UsuButton, UsuButtonGroup, UsuCheckbox, UsuSearchInput } from '@usu/react-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexRow } from '../../../component/common/layout/Flex';
import { Group } from '../../group/group.model';
import { useAddUserToGroupMutation } from '../../group/group.mutations';
import { isNil } from '../../../support/util';

interface IUserJoinGroupDrawer {
  user: User;
  dismiss(): void;
}

export const UserJoinGroupsDrawer = ({ user, dismiss }: IUserJoinGroupDrawer) => {
  const { t } = useTranslation();
  const availableGroupsQuery = useUserAvailableGroupsQuery(user.id);

  return (
    <>
      <h2>{t('users.join_group.title', { username: user.attributes.username })}</h2>
      <div className={'mg-t-nm'} style={{ width: '100%', maxWidth: '686px' }}>
        <QueryGuard query={availableGroupsQuery}>
          {{
            success: ({ query }) => {
              return <GroupList user={user} groups={query.data!.data} dismiss={dismiss} />;
            },
          }}
        </QueryGuard>
      </div>
    </>
  );
};

interface IGroupList extends IUserJoinGroupDrawer {
  groups: Group[];
}

const GroupList = ({ user, groups, dismiss }: IGroupList) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const selection = useRef<string[]>();
  const [selected, setSelected] = useState<string[]>([]);
  const joinGroupMutation = useAddUserToGroupMutation();

  useEffect(() => {
    selection.current = selected;
  }, [selected]);

  const onSubmit = async () => {
    if (!isNil(selection.current)) {
      await Promise.all(
        selection.current.map(async (groupId) => {
          await joinGroupMutation.mutateAsync({ groupId, userId: user.id });
        })
      );
      dismiss();
    }
  };

  const handleChange = (id: string, checked: boolean) => {
    setSelected((prevSelection) => (checked ? [...prevSelection, id] : prevSelection.filter((s) => s !== id)));
  };

  return (
    <>
      <UsuSearchInput
        className={'mg-b-lg'}
        style={{ width: '100%' }}
        placeholder={t('general.search_by_group_name')}
        value={search}
        onUsuSearch={(ev) => {
          const value = ((ev.detail.value as string) || '').trim();
          if (value && value.length > 0) {
            setSearch(value);
          }
        }}
        onUsuInput={(ev) => {
          if (search && ev.detail.value === '') {
            setSearch(undefined);
          }
        }}
      />
      {groups
        .filter((g) => {
          return search ? g.attributes.name.match(new RegExp(search, 'i')) !== null : true;
        })
        .map((item) => {
          return (
            <div key={item.id}>
              <UsuCheckbox onUsuChange={(e) => handleChange(item.id, e.detail.checked)}>
                {item.attributes.name}
              </UsuCheckbox>
            </div>
          );
        })}
      <FlexRow justifyContent={'flex-end'} className={'mg-t-lg'}>
        <UsuButtonGroup>
          <UsuButton color={'secondary'} onClick={dismiss}>
            {t('general.close')}
          </UsuButton>
          <UsuButton onClick={onSubmit}>{t('general.join')}</UsuButton>
        </UsuButtonGroup>
      </FlexRow>
    </>
  );
};
