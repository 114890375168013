import { Fragment } from 'react';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { UserFederation } from '../../../integration.model';
import { useTranslation } from 'react-i18next';
import { UserFederationInfoLabel } from './UserFederationInfoLabel';

interface IUserFederationSynchronizationPanel {
  userFederation: UserFederation;
}

export const UserFederationSynchronizationPanel = ({ userFederation }: IUserFederationSynchronizationPanel) => {
  const { t } = useTranslation();
  const { synchronization } = userFederation.attributes;
  const { importEnabled, syncRegistrations, fullSyncPeriod, changedSyncPeriod, batchSizeForSync } = synchronization;

  const info = [
    {
      label: t('integrations.import_enabled.label'),
      value: importEnabled ? t('general.yes') : t('general.no'),
      description: t('integrations.import_enabled.description'),
    },
    {
      label: t('integrations.sync_registrations.label'),
      value: syncRegistrations ? t('general.yes') : t('general.no'),
      description: t('integrations.sync_registrations.description'),
    },
    {
      label: t('integrations.full_sync_period.label'),
      value: fullSyncPeriod === -1 ? t('general.disabled') : fullSyncPeriod,
      description: t('integrations.full_sync_period.description'),
    },
    {
      label: t('integrations.changed_sync_period.label'),
      value: changedSyncPeriod === -1 ? t('general.disabled') : changedSyncPeriod,
      description: t('integrations.changed_sync_period.description'),
    },
    {
      label: t('integrations.batch_size.label'),
      value: batchSizeForSync || 0,
      description: t('integrations.batch_size.description'),
    },
  ];

  return (
    <div>
      <InfoGrid>
        {info.map(({ label, value, description }) => (
          <Fragment key={label}>
            <InfoGrid.Label>
              <UserFederationInfoLabel label={label} description={description} />
            </InfoGrid.Label>
            <InfoGrid.Value>{value}</InfoGrid.Value>
          </Fragment>
        ))}
      </InfoGrid>
    </div>
  );
};
