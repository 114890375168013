import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { UsuIcon, UsuModalDialog } from '@usu/react-components';
import { InfoGrid } from '../layout/InfoGrid';
import { QueryGuard } from '../api/QueryGuard';
import { FlexRow } from '../layout/Flex';
import { Section } from '../layout/Section';
import { useInfoLight } from '../../../domain/realm/realm.queries';

interface IAboutDialog {}

export const AboutDialog = (_: IAboutDialog) => {
  const { t } = useTranslation();
  const infoQuery = useInfoLight();

  return (
    <UsuModalDialog style={{ maxWidth: '500px' }}>
      <FlexRow alignItems={'center'} slot='header'>
        <UsuIcon type={'information'} className={'mg-r-xs'} />
        {t('general.about')}
      </FlexRow>
      <div slot={'content'}>
        <Section title={t('about.license.header')} className={'mg-b-nm'}>
          <p>{t('about.license.description')}</p>
          <br />
          <p>©USU GmbH. {t('about.license.all_rights_reserved')}</p>
        </Section>

        <Section title={t('about.version.header')}>
          <QueryGuard query={infoQuery}>
            {{
              success: ({ query }) => {
                const { data } = query;
                const info = [
                  { label: 'User Management:', value: `v${data!.attributes.uumVersion}` },
                  { label: 'Keycloak:', value: `v${data!.attributes.keycloakVersion}` },
                ];

                return (
                  <InfoGrid>
                    {info.map(({ label, value }) => {
                      return (
                        <Fragment key={label}>
                          <InfoGrid.Label>{label}</InfoGrid.Label>
                          <InfoGrid.Value>{value}</InfoGrid.Value>
                        </Fragment>
                      );
                    })}
                  </InfoGrid>
                );
              },
            }}
          </QueryGuard>
        </Section>
      </div>
    </UsuModalDialog>
  );
};
