import type { TFunction } from 'i18next';
import { ColumnType } from '@usu/react-ui';

export const GROUP_MEMBERS_TABLE_COLUMNS = (t: TFunction) =>
  [
    {
      type: ColumnType.String,
      id: 'username',
      accessor: ['attributes', 'username'],
      header: t('general.username'),
    },
    {
      type: ColumnType.String,
      id: 'email',
      accessor: ['attributes', 'email'],
      header: t('general.email'),
    },
    {
      type: ColumnType.Action,
      id: 'actions',
      cellMutator: true,
      header: t('general.actions'),
    },
  ] as const;

export const GROUP_ROLES_TABLE_COLUMNS = (t: TFunction) =>
  [
    {
      type: ColumnType.String,
      id: 'name',
      accessor: ['attributes', 'name'],
      header: t('general.name'),
    },
    {
      type: ColumnType.Boolean,
      id: 'inherited',
      accessor: ['attributes', 'inherited'],
      header: t('general.inherited'),
    },
    {
      type: ColumnType.Action,
      id: 'actions',
      cellMutator: true,
      header: t('general.actions'),
    },
  ] as const;
