import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import {
  UsuButton,
  UsuContentHeader,
  UsuDataMessage,
  UsuLink,
  UsuPageLayout,
  UsuProgressIndicator,
} from '@usu/react-components';
import { PAGE_IDS } from '../../../config/paths';
import { LayoutHeader } from './LayoutHeader';
import { AccountGuard } from '../../../domain/account/component/AccountGuard';
import { Timeout } from '../../common/misc/Timeout';
import { LayoutSidebar } from './LayoutSidebar';
import { useNav } from '../../../config/routes';

export const Layout = () => {
  return (
    <AccountGuard
      loading={<LayoutLoading />}
      forbidden={<LayoutErrorAccessDenied />}
      unresolved={<LayoutErrorInvalidAppState />}
    >
      {(auth, account) => (
        <UsuPageLayout>
          <LayoutHeader initialized account={account} onLogoutClick={() => auth.userManager.signoutRedirect()} />
          <LayoutSidebar />
          <Outlet />
        </UsuPageLayout>
      )}
    </AccountGuard>
  );
};

export const LayoutLoading = ({ errorTimeoutDuration }: { errorTimeoutDuration?: number }) => {
  const { t } = useTranslation();

  return (
    <UsuPageLayout>
      <LayoutHeader />
      <div slot={'content'} className={'splash'}>
        <Timeout
          duration={errorTimeoutDuration}
          whenExpired={
            <UsuDataMessage status={'error'}>
              <span slot={'title'}>{t('general.layout.loading.error.title')}</span>
              <span slot={'message'}>{t('general.layout.loading.error.message')}</span>
            </UsuDataMessage>
          }
        >
          <UsuContentHeader heading={t('general.layout.loading.title')} />
          <p className={'mg-b-xl'}>{t('general.layout.loading.message')}</p>
          <UsuProgressIndicator shape={'circle'} hidePercentage infinite />
        </Timeout>
      </div>
    </UsuPageLayout>
  );
};

const LayoutErrorInvalidAppState = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <UsuPageLayout>
      <LayoutHeader />
      <div slot={'content'} className={'splash'}>
        <UsuDataMessage status={'error'}>
          <span slot={'title'}>{t('general.layout.unresolved.title')}</span>
          <span slot={'message'}>{t('general.layout.unresolved.message')}</span>
          <div slot={'action'}>
            <UsuButton color={'primary'} onClick={() => auth.userManager.signoutRedirect()}>
              {t('general.layout.unresolved.reset-state')}
            </UsuButton>
          </div>
        </UsuDataMessage>
      </div>
    </UsuPageLayout>
  );
};

export const LayoutErrorPageNotFound = () => {
  const { t } = useTranslation();
  const nav = useNav();

  return (
    <UsuPageLayout>
      <LayoutHeader />
      <div slot={'content'} className={'splash'}>
        <UsuDataMessage status={'error'}>
          <span slot={'title'}>{t('general.layout.notFound.title')}</span>
          <span slot={'message'}>{t('general.layout.notFound.message')}</span>
          <div slot={'action'}>
            <UsuButton color={'primary'} onClick={() => nav({ id: PAGE_IDS.dashboard })}>
              {t('general.layout.notFound.goHome')}
            </UsuButton>
          </div>
        </UsuDataMessage>
      </div>
    </UsuPageLayout>
  );
};

const LayoutErrorAccessDenied = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <UsuPageLayout>
      <LayoutHeader
        initialized
        accessDenied
        account={auth.userData!}
        onLogoutClick={() => auth.userManager.signoutRedirect()}
      />
      <div slot={'content'} className={'splash'}>
        <UsuDataMessage status={'no-access'}>
          <span slot={'title'}>{t('general.layout.access_denied.title')}</span>
          <span slot={'message'}>{t('general.layout.access_denied.message')}</span>
          <div slot={'action'}>
            <UsuLink href={'mailto:support-sam@usu.com'}>support-sam@usu.com</UsuLink>
          </div>
        </UsuDataMessage>
      </div>
    </UsuPageLayout>
  );
};
