import { PasswordPolicy } from './password-policy.model';

export const passwordValidations = [
  {
    id: 'length',
    validation: (value: string, requiredLength: number) => value?.length >= requiredLength,
    translationKey: 'min_length',
  },
  {
    id: 'maxLength',
    validation: (value: string, maxLength: number) => value?.length <= maxLength,
    translationKey: 'max_length',
  },
  {
    id: 'upperCase',
    validation: (value: string, requiredAmount: number) => value.match(/[A-Z]/g)?.length ?? 0 >= requiredAmount,
    translationKey: 'uppercase',
  },
  {
    id: 'lowerCase',
    validation: (value: string, requiredAmount: number) => value.match(/[a-z]/g)?.length ?? 0 >= requiredAmount,
    translationKey: 'lowercase',
  },
  {
    id: 'notUsername',
    validation: (value: string, username: string) => value !== username,
    translationKey: 'not_username',
  },
  {
    id: 'notEmail',
    validation: (value: string, email: string) => value !== email,
    translationKey: 'not_email',
  },
  {
    id: 'specialChars',
    validation: (value: string, requiredAmount: number) =>
      value?.match(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~/?]/g)?.length ?? 0 >= requiredAmount,
    translationKey: 'special_chars',
  },
  {
    id: 'digits',
    validation: (value: string, requiredAmount: number) => value.replace(/[^0-9]/g, '').length >= +requiredAmount,
    translationKey: 'digits',
  },
];

export const getPasswordValidationById = (policy: PasswordPolicy) => {
  return passwordValidations.find((v) => v.id === policy.id);
};

export const validatePasswordPolicy = (password: undefined | string, policy: PasswordPolicy) => {
  const validation = getPasswordValidationById(policy);
  // @ts-ignore TODO
  return validation?.validation(password ?? '', policy.attributes.value) ?? false;
};

export const checkPasswordValidity = (password: string, policies: PasswordPolicy[]) => {
  for (const policy of policies) {
    const validation = getPasswordValidationById(policy);
    // @ts-ignore TODO
    if (!validation?.validation(password, policy.attributes.value)) {
      return false;
    }
  }
  return true;
};
