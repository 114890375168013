import { API_PATHS } from '../../config/paths';
import { ApiMutationFn, useApiMutation } from '../../support/api';
import { HttpResponse } from '../../support/http';
import { useTranslation } from 'react-i18next';
import { useUsuToast } from '@usu/react-components';
import { SyncAction } from './integration.model';
import { useQueryClient } from '@tanstack/react-query';
import { USER_FEDERATIONS_QUERY_KEY } from './integration.queries';

const deleteUserFederationMutation: ApiMutationFn<HttpResponse<any>, { federationId: string }> = (
  http,
  { federationId }
) => http.delete<any>(API_PATHS.integration.userFederation.single(federationId));

export const useDeleteUserFederationMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(deleteUserFederationMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_FEDERATIONS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: t('integrations.delete.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('integrations.delete.error'),
      });
    },
  });
};

const syncFederatedUsersMutation: ApiMutationFn<HttpResponse<any>, { federationId: string; action: SyncAction }> = (
  http,
  { federationId, action }
) => {
  const queryString = new URLSearchParams({ action }).toString();
  return http.post<any>(`${API_PATHS.integration.userFederation.sync(federationId)}?${queryString}`);
};

export const useSyncFederatedUsersMutation = () => {
  const { t } = useTranslation();
  const toast = useUsuToast();

  return useApiMutation(syncFederatedUsersMutation, {
    onSuccess: () => {
      toast.show({
        status: 'success',
        header: t('integrations.sync.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('integrations.sync.error'),
      });
    },
  });
};

const unlinkFederatedUsersMutation: ApiMutationFn<HttpResponse<any>, { federationId: string }> = (
  http,
  { federationId }
) => http.post<any>(API_PATHS.integration.userFederation.unlinkUsers(federationId));

export const useUnlinkFederatedUsersMutation = () => {
  const { t } = useTranslation();
  const toast = useUsuToast();

  return useApiMutation(unlinkFederatedUsersMutation, {
    onSuccess: () => {
      toast.show({
        status: 'success',
        header: t('integrations.unlink_users.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('integrations.unlink_users.error'),
      });
    },
  });
};

const removeFederatedUsersMutation: ApiMutationFn<HttpResponse<any>, { federationId: string }> = (
  http,
  { federationId }
) => http.post<any>(API_PATHS.integration.userFederation.removeUsers(federationId));

export const useRemoveFederatedUsersMutation = () => {
  const { t } = useTranslation();
  const toast = useUsuToast();

  return useApiMutation(removeFederatedUsersMutation, {
    onSuccess: () => {
      toast.show({
        status: 'success',
        header: t('integrations.remove_users.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('integrations.remove_users.error'),
      });
    },
  });
};
