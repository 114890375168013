import { PageProps } from '../../config/routes';
import { Page } from '../common/layout/Page';
import { ClientsList } from '../../domain/client/component/ClientsList';

export const Clients = ({ page }: PageProps) => {
  return (
    <Page heading={page.title}>
      <ClientsList />
    </Page>
  );
};
