import { UsuIcon, UsuTooltip } from '@usu/react-components';
import { FlexRow } from '../../../../../component/common/layout/Flex';

interface IUserFederationInfoLabel {
  label: string;
  description?: string;
}

export const UserFederationInfoLabel = ({ label, description }: IUserFederationInfoLabel) => {
  return (
    <FlexRow>
      <span>{label}</span>
      {description && (
        <UsuTooltip>
          <UsuIcon type={'help'} size={'small'} className={'mg-l-xs txt-c-info'} />
          <span slot='message'>{description}</span>
        </UsuTooltip>
      )}
    </FlexRow>
  );
};
