import { Group } from '../../../group.model';
import { useGroupEffectiveRolesQuery } from '../../../group.queries';
import { QueryGuard } from '../../../../../component/common/api/QueryGuard';
import { useMemo } from 'react';
import { autoTableColumns, ConfirmationAction, Table } from '@usu/react-ui';
import { GROUP_ROLES_TABLE_COLUMNS } from '../../../group.config';
import { UsuButton } from '@usu/react-components';
import { useTranslation } from 'react-i18next';
import { useRemoveClientRolesFromGroupMutation } from '../../../group.mutations';
import { useDrawer } from '../../../../../support/hooks';
import { GroupAssignRoleDrawer } from '../../GroupAssignRoleDrawer';

interface IGroupRolesPanel {
  group: Group;
}

export const GroupRolesPanel = ({ group }: IGroupRolesPanel) => {
  const { t } = useTranslation();
  const [present, dismiss] = useDrawer(GroupAssignRoleDrawer, { group, dismiss: () => dismiss() });
  const rolesQuery = useGroupEffectiveRolesQuery(group.id);
  const removeClientRolesFromGroupMutation = useRemoveClientRolesFromGroupMutation();

  const columns = useMemo(
    () =>
      autoTableColumns({
        // @ts-ignore
        columns: GROUP_ROLES_TABLE_COLUMNS(t),
        actions: {
          actions: {
            cellMutator: (d) => (
              <ConfirmationAction
                title={t('groups.unassign_role.confirm.title')}
                message={t('groups.unassign_role.confirm.message')}
                onConfirm={() =>
                  removeClientRolesFromGroupMutation.mutate({
                    groupId: group.id,
                    clientId: d.attributes.clientId,
                    roleNames: [d.attributes.name],
                  })
                }
              >
                {({ open }) => (
                  <UsuButton color={'tertiary'} onClick={open}>
                    {t('general.unassign')}
                  </UsuButton>
                )}
              </ConfirmationAction>
            ),
          },
        },
      }),
    [t]
  );

  return (
    <QueryGuard query={rolesQuery}>
      {{
        success: ({ query }) => {
          return (
            <Table
              id={`group-${group.id}-roles`}
              toolbar={
                <UsuButton size={'small'} onClick={present}>
                  {t('groups.assign_roles.label')}
                </UsuButton>
              }
              columns={columns}
              data={query.data?.data}
            />
          );
        },
      }}
    </QueryGuard>
  );
};
