import { InputField, useForm } from '@usu/react-form';
import { useTranslation } from 'react-i18next';
import { useCreateGroupMutation, useCreateSubgroupMutation } from '../group.mutations';
import { FlexRow } from '../../../component/common/layout/Flex';
import { UsuButton, UsuButtonGroup, UsuIcon, UsuSectionMessage } from '@usu/react-components';
import { Group } from '../group.model';

interface ICreateGroupDrawer {
  dismiss(): void;
  parent?: Group;
}

interface IForm {
  name: string;
  description: string;
}

export const CreateGroupDrawer = ({ parent, dismiss }: ICreateGroupDrawer) => {
  const { t } = useTranslation();
  const form = useForm<IForm>({ initialValues: { name: '', description: '' } });
  const createGroupMutation = useCreateGroupMutation(form);
  const createSubgroupMutation = useCreateSubgroupMutation(form);

  const onSubmit = ({ name, description }: IForm) => {
    const payload = { name, attributes: { description: [description] } };
    if (!parent) {
      createGroupMutation.mutate(payload, {
        onSuccess: dismiss,
      });
    } else {
      createSubgroupMutation.mutate(
        { parentId: parent.id, ...payload },
        {
          onSuccess: dismiss,
        }
      );
    }
  };

  return (
    <>
      <h2>{t('groups.create.label')}</h2>
      <div className={'mg-t-nm'} style={{ width: '686px' }}>
        {/* info */}
        <UsuSectionMessage hideCloseButton className={'mg-b-nm'}>
          <div slot='title'>{t('groups.create.info.title')}</div>
          <div>{t('groups.create.info.message')}</div>
        </UsuSectionMessage>
        <form onSubmit={form.onSubmit(onSubmit)}>
          {/* inputs */}
          <InputField
            className={'mg-b-nm'}
            required
            label={t('general.name')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('name')}
          />
          <InputField
            label={t('general.description')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('description')}
          />

          {/* footer */}
          <FlexRow className={'mg-t-nm'} justifyContent={'flex-end'} alignItems={'center'}>
            <UsuButtonGroup>
              <UsuButton onClick={dismiss} color={'secondary'}>
                {t('general.close')}
              </UsuButton>
              <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
                {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
                {t('groups.create.label')}
              </UsuButton>
            </UsuButtonGroup>
          </FlexRow>
        </form>
      </div>
    </>
  );
};
