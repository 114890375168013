import { HOST_URL } from '../support/env';
import { AuthProviderProps } from 'oidc-react';
import { User } from 'oidc-client-ts';

export const KEYCLOAK_CLIENT_ID = 'usu:ui';

/**
 * Authentication configuration
 * `authority` is provided by backend via MetaEnv (see domain/meta/meta.model.ts)
 */
export const authConfig: Omit<AuthProviderProps, 'authority' | 'clientId'> = {
  get redirectUri() {
    return getRedirectUrl();
  },
  get postLogoutRedirectUri() {
    return getRedirectUrl();
  },
  scope: 'openid profile email',
  autoSignIn: true,
  autoSignOut: true,
  automaticSilentRenew: true,
  onSignIn,
};

/**
 * Since `oidc-react` doesn't remove authentication-related query parameters from the
 * sign-in redirect, we do it manually by replacing the current history state once
 */
function onSignIn(_: User | null) {
  if (history) {
    history.replaceState(undefined, '', location.pathname);
  }
}

/**
 * Helper used for getters in `authConfig` to return a redirect URL for authentication flows.
 * This is used instead of `location.href` or similar to effectively strip hash and query
 * parameters from the URL.
 */
function getRedirectUrl() {
  return `${HOST_URL}${location.pathname}`;
}
