import { PageProps } from '../../config/routes';
import { FlexCol, FlexRow } from '../common/layout/Flex';
import { InfoGrid } from '../common/layout/InfoGrid';
import { UsuTag, UsuWidgetDashlet } from '@usu/react-components';
import { useClientSessions, useInfo } from '../../domain/realm/realm.queries';
import { QueryGuard } from '../common/api/QueryGuard';
import { toMbAndRoundResult } from '../../support/util';
import { useUsersQuery } from '../../domain/user/user.queries';
import { useGroupsQuery } from '../../domain/group/group.queries';
import { useClientsQuery } from '../../domain/client/client.queries';
import { PKG } from '../../support/env';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode } from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartOptions } from '../../support/echart';

export const Dashboard = ({}: PageProps) => {
  return (
    <div slot={'content'}>
      <div className={'dashboard-grid'}>
        <KeycloakInfo />
        <UmInfo />
        <ActiveSessions />
        <DataSummaryInfo />
      </div>
    </div>
  );
};

interface IMemoryInfo {
  memoryUsed: number;
  memoryFree: number;
  memoryTotal: number;
}

const USED_COLOR = '#004f9f';
const FREE_COLOR = '#9a9a9a';

const MemoryInfo = ({ memoryUsed, memoryFree, memoryTotal }: IMemoryInfo) => {
  const { t } = useTranslation();

  const pieOptions: EChartOptions = {
    series: [
      {
        name: 'Keycloak Memory',
        type: 'pie',
        data: [
          { value: memoryUsed, name: t('general.used'), itemStyle: { color: USED_COLOR } },
          { value: memoryFree, name: t('general.free'), itemStyle: { color: FREE_COLOR } },
        ],
        radius: ['65%', '85%'],
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 2,
        },
        percentPrecision: 0,
        label: {
          show: true,
          formatter: (params: any) => {
            if (params.name === 'Used') {
              return `${params.percent}%`;
            } else {
              return `${100 - params.percent}%`;
            }
          },
          position: 'center',
          fontSize: 16,
        },
        cursor: 'default',
        emphasis: { disable: true, scale: false },
      },
    ],
  };

  return (
    <>
      <h4>{t('general.memory')}</h4>
      <FlexRow alignItems={'center'}>
        <ReactECharts option={pieOptions} className={'mg-r-nm'} style={{ height: '70px', width: '70px' }} />
        <FlexCol>
          <FlexRow>{t('general.memory')} (MB)</FlexRow>
          <FlexRow>
            <span style={{ color: USED_COLOR }}>
              {t('general.used')} {toMbAndRoundResult(memoryUsed)} MB
            </span>
            <span className={'mg-h-xs'}>/</span>
            <span style={{ color: FREE_COLOR }}>
              {t('general.total')} {toMbAndRoundResult(memoryTotal)} MB
            </span>
          </FlexRow>
        </FlexCol>
      </FlexRow>
    </>
  );
};

const KeycloakInfo = () => {
  const { t } = useTranslation();
  const infoQuery = useInfo();

  return (
    <UsuWidgetDashlet className={'dashboard-grid__item'}>
      <div slot='title'>Keycloak</div>
      <div slot='body'>
        <QueryGuard query={infoQuery}>
          {{
            success: ({ query }) => {
              const { mailServerConnected, server } = query.data!.attributes;
              const { memoryTotal, memoryAvailable, version } = server;
              const kcMemoryTotal = memoryTotal || 0;
              const kcMemoryAvailable = memoryAvailable || 0;
              const kcMemoryUsed = kcMemoryTotal - kcMemoryAvailable;

              const info: { label: string; value: string | ReactNode }[] = [
                { label: t('general.version'), value: version },
                { label: t('general.realm'), value: query.data!.id },
                {
                  label: t('general.smtp_status'),
                  value: (
                    <UsuTag size={'small'} color={mailServerConnected ? 'green' : 'red'}>
                      {mailServerConnected ? 'connected' : 'disconnected'}
                    </UsuTag>
                  ),
                },
              ];

              return (
                <>
                  <InfoGrid>
                    {info.map(({ label, value }) => (
                      <Fragment key={label}>
                        <InfoGrid.Label>{label}</InfoGrid.Label>
                        <InfoGrid.Value>{value}</InfoGrid.Value>
                      </Fragment>
                    ))}
                  </InfoGrid>
                  <div className={'mg-t-lg'}>
                    <MemoryInfo memoryUsed={kcMemoryUsed} memoryFree={kcMemoryAvailable} memoryTotal={kcMemoryTotal} />
                  </div>
                </>
              );
            },
          }}
        </QueryGuard>
      </div>
    </UsuWidgetDashlet>
  );
};

const UmInfo = () => {
  const { t } = useTranslation();
  const infoQuery = useInfo();

  return (
    <UsuWidgetDashlet className={'dashboard-grid__item'}>
      <div slot='title'>USU User Management</div>
      <div slot='body'>
        <QueryGuard query={infoQuery}>
          {{
            success: ({ query }) => {
              const { memoryTotal, memoryAvailable, version } = query.data!.attributes.application;
              const umMemoryTotal = memoryTotal;
              const umMemoryAvailable = memoryAvailable;
              const umMemoryUsed = umMemoryTotal - umMemoryAvailable;

              const info: { label: string; value: string | ReactNode }[] = [
                { label: t('general.service_version'), value: version },
                { label: t('general.admin_ui_version'), value: PKG.version },
                { label: t('general.theming'), value: <UsuTag size={'small'}>{t('general.inactive')}</UsuTag> },
              ];

              return (
                <>
                  <InfoGrid>
                    {info.map(({ label, value }) => (
                      <Fragment key={label}>
                        <InfoGrid.Label>{label}</InfoGrid.Label>
                        <InfoGrid.Value>{value}</InfoGrid.Value>
                      </Fragment>
                    ))}
                  </InfoGrid>
                  <div className={'mg-t-lg'}>
                    <MemoryInfo memoryUsed={umMemoryUsed} memoryFree={umMemoryAvailable} memoryTotal={umMemoryTotal} />
                  </div>
                </>
              );
            },
          }}
        </QueryGuard>
      </div>
    </UsuWidgetDashlet>
  );
};

const ActiveSessions = () => {
  const { t } = useTranslation();
  const clientSessionsQuery = useClientSessions();
  return (
    <UsuWidgetDashlet className={'dashboard-grid__item'}>
      <div slot='title'>{t('general.active_session.pl')}</div>
      <FlexRow slot='body' justifyContent={'center'}>
        <QueryGuard query={clientSessionsQuery}>
          {{
            success: ({ query }) => {
              const data = query.data!.map((clientSession) => {
                const { active, clientId } = clientSession.attributes;
                return {
                  value: active,
                  name: clientId,
                };
              });

              const pieOptions: EChartOptions = {
                tooltip: {
                  show: true,
                },
                series: [
                  {
                    name: t('general.active_session.sl'),
                    type: 'pie',
                    data,
                    cursor: 'default',
                  },
                ],
              };

              return <ReactECharts style={{ height: '300px', width: '400px' }} option={pieOptions} />;
            },
          }}
        </QueryGuard>
      </FlexRow>
    </UsuWidgetDashlet>
  );
};

const DataSummaryInfo = () => {
  const { t } = useTranslation();
  const clientsQuery = useClientsQuery();
  const groupsQuery = useGroupsQuery();
  const usersQuery = useUsersQuery();
  const clientsTotal = clientsQuery?.[0].data?.page.totalElements || 0;
  // @ts-ignore
  const groupsTotal = groupsQuery.data?.page.totalElements || 0;
  const usersTotal = usersQuery?.[0].data?.page.totalElements || 0;

  const pieOptions: EChartOptions = {
    tooltip: {
      show: true,
    },
    series: [
      {
        type: 'pie',
        data: [
          { name: t('general.clients'), value: clientsTotal },
          { name: t('general.groups'), value: groupsTotal },
          { name: t('general.users'), value: usersTotal },
        ],
        cursor: 'default',
      },
    ],
  };

  return (
    <UsuWidgetDashlet className={'dashboard-grid__item'}>
      <div slot='title'>{t('general.data_summary')}</div>
      <FlexRow slot='body' justifyContent={'center'}>
        <ReactECharts style={{ height: '300px', width: '400px' }} option={pieOptions} />
      </FlexRow>
    </UsuWidgetDashlet>
  );
};
