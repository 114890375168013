import { Group, GroupRequestBody } from '../../../group.model';
import { useTranslation } from 'react-i18next';
import { InputField, useForm } from '@usu/react-form';
import { UsuButton, UsuButtonGroup, UsuIcon } from '@usu/react-components';
import { copyToClipboard } from '../../../../../support/util';
import { FlexRow } from '../../../../../component/common/layout/Flex';
import { useUpdateGroupMutation } from '../../../group.mutations';

interface IGroupDetailsPanel {
  group: Group;
}

interface IForm {
  name: string;
  description: string;
}

export const GroupDetailsPanel = ({ group }: IGroupDetailsPanel) => {
  const { t } = useTranslation();
  const form = useForm<IForm>({
    initialValues: { name: group.attributes.name, description: group.attributes.attributes.description?.[0] || '' },
  });
  const updateGroupMutation = useUpdateGroupMutation(form);

  const onSubmit = ({ name, description }: IForm) => {
    // TODO fix endpoint
    if (form.isDirty('name')) {
      const payload: GroupRequestBody = { name };
      updateGroupMutation.mutate({ groupId: group.id, ...payload });
    }
    if (form.isDirty('description')) {
      const payload: GroupRequestBody = { attributes: { description: [description] } };
      updateGroupMutation.mutate({ groupId: group.id, ...payload });
    }
  };

  return (
    <>
      <FlexRow className={'mg-b-nm'} alignItems={'center'}>
        <InputField disabled label={t('general.uuid')} value={group.id} style={{ width: '100%' }} />
        <UsuButton
          className={'mg-l-sm mg-t-nm'}
          shape={'circle'}
          color={'tertiary'}
          size={'small'}
          onClick={() => copyToClipboard(group.id)}
        >
          <UsuIcon type={'duplicate'} />
        </UsuButton>
      </FlexRow>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <InputField
          className={'mg-b-nm'}
          required
          label={t('general.name')}
          placeholder={t('general.enter_value')}
          {...form.getInputProps('name')}
        />
        <InputField
          label={t('general.description')}
          placeholder={t('general.enter_value')}
          {...form.getInputProps('description')}
        />

        {/* footer */}
        <FlexRow className={'mg-t-nm'} justifyContent={'flex-start'} alignItems={'center'}>
          <UsuButtonGroup>
            <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
              {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
              {t('general.save')}
            </UsuButton>
          </UsuButtonGroup>
        </FlexRow>
      </form>
    </>
  );
};
