import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import classNames, { Argument as CSSClass } from 'classnames';
import { UsuDataMessage } from '@usu/react-components';

interface INoDataPanel {
  className?: CSSClass;
  style?: CSSProperties;
  slot?: string;
  message?: string;
}

export function NoDataPanel(props: INoDataPanel) {
  const { t } = useTranslation();

  return (
    <div className={classNames('no-data-panel', props.className)} style={props.style} slot={props.slot}>
      <UsuDataMessage status={'no-data'}>
        <span slot={'message'}>{props.message || t('general.api.noData.message')}</span>
      </UsuDataMessage>
    </div>
  );
}
