import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { useUserFederationsQuery } from '../integration.queries';
import { UserFederationCard } from './user-federation-card/UserFederationCard';
import { isEmpty } from '../../../support/util';
import { FlexRow } from '../../../component/common/layout/Flex';
import { UsuPaginator, UsuProgressIndicator, UsuSearchInput } from '@usu/react-components';
import { useTranslation } from 'react-i18next';
import { NoDataPanel } from '../../../component/common/misc/NoDataPanel';

interface IUserFederationsList {}

export const UserFederationsList = (_: IUserFederationsList) => {
  const { t } = useTranslation();
  const [userFederationsQuery, params, goToPage, updateParams] = useUserFederationsQuery();

  if (userFederationsQuery.isLoading) {
    return <UsuProgressIndicator infinite size={'small'} shape={'circle'} />;
  }

  if (isEmpty(userFederationsQuery.data?.data)) {
    return <NoDataPanel />;
  }

  return (
    <>
      <FlexRow className='pd-t-nm pd-b-nm' alignItems={'center'}>
        <UsuSearchInput
          placeholder={t('integrations.search_placeholder')}
          value={params.search}
          onUsuSearch={(ev) => {
            const search = ((ev.detail.value as string) || '').trim();
            if (search && search.length > 0) {
              updateParams({ search, page: 0 });
            }
          }}
          onUsuInput={(ev) => {
            if (params.search && ev.detail.value === '') {
              updateParams({ search: undefined, page: 0 });
            }
          }}
          style={{ flex: '0 0 250px' }}
        />
      </FlexRow>
      <div>
        <QueryGuard query={userFederationsQuery}>
          {{
            success: ({ query }) => {
              return (
                <section className={'integration-grid'} data-empty={isEmpty(query.data?.data)}>
                  {query.data?.data.map((userFederation) => (
                    <UserFederationCard key={userFederation.id} userFederation={userFederation} />
                  ))}
                </section>
              );
            },
          }}
        </QueryGuard>
      </div>
      <FlexRow alignItems={'center'} justifyContent={'center'} className={'pd-t-nm'}>
        <UsuPaginator
          min={1}
          max={params.totalPages || 1}
          current={params.page || 1}
          onUsuChange={(e) => goToPage(e.detail.currentPage)}
        />
      </FlexRow>
    </>
  );
};
