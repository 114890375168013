import { API_PATHS } from '../../config/paths';
import { useApiQuery } from '../../support/api';
import { ApiClientSessionData, ApiInfoData, ApiInfoLightData, ApiLocaleData, Locale } from './realm.model';

export const useInfo = () => {
  return useApiQuery(['realm', 'info'], {
    queryFn: (http, ctx) =>
      http.get<ApiInfoData>(API_PATHS.realm.info, { signal: ctx.signal }).then((res) => res.data.data),
  });
};

export const useInfoLight = () => {
  return useApiQuery(['realm', 'info-light'], {
    queryFn: (http, ctx) =>
      http.get<ApiInfoLightData>(API_PATHS.realm.infoLight, { signal: ctx.signal }).then((res) => res.data.data),
  });
};

export const useSupportedLocalesQuery = () => {
  return useApiQuery(['realm', 'locales', 'supported'], {
    queryFn: (http, ctx) =>
      http.get<ApiLocaleData>(API_PATHS.realm.locales.supported, { signal: ctx.signal }).then((res) => res.data.data),
  });
};

export const useAvailableLocalesQuery = () => {
  return useApiQuery(['realm', 'locales', 'available'], {
    queryFn: (http, ctx) =>
      http.get<ApiLocaleData>(API_PATHS.realm.locales.available, { signal: ctx.signal }).then((res) => res.data.data),
  });
};

export const useClientSessions = () => {
  return useApiQuery(['realm', 'client-sessions'], {
    queryFn: (http, ctx) =>
      http
        .get<ApiClientSessionData>(API_PATHS.realm.clientSessions, { signal: ctx.signal })
        .then((res) => res.data.data),
  });
};
