import { Fragment } from 'react';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { UserFederation } from '../../../integration.model';
import { useTranslation } from 'react-i18next';
import { UserFederationInfoLabel } from './UserFederationInfoLabel';

interface IUserFederationDetailsPanel {
  userFederation: UserFederation;
}

export const UserFederationDetailsPanel = ({ userFederation }: IUserFederationDetailsPanel) => {
  const { t } = useTranslation();
  const {
    id,
    attributes: { connection, synchronization },
  } = userFederation;
  const { vendor, editMode, trustEmail, passwordModifyExtendedOperationEnabled } = connection;
  const { validatePasswordPolicy } = synchronization;

  const info = [
    { label: t('general.id'), value: id },
    { label: t('integrations.vendor'), value: vendor },
    { label: t('integrations.edit_mode.label'), value: editMode, description: t('integrations.edit_mode.description') },
    {
      label: t('integrations.trust_email.label'),
      value: trustEmail ? t('general.yes') : t('general.no'),
      description: t('integrations.trust_email.description'),
    },
    {
      label: t('integrations.validate_password.label'),
      value: validatePasswordPolicy ? t('general.yes') : t('general.no'),
      description: t('integrations.validate_password.description'),
    },
    {
      label: t('integrations.extended_password_modify.label'),
      value: passwordModifyExtendedOperationEnabled ? t('general.yes') : t('general.no'),
      description: t('integrations.extended_password_modify.description'),
    },
  ];

  return (
    <div>
      <InfoGrid>
        {info.map(({ label, value, description }) => (
          <Fragment key={label}>
            <InfoGrid.Label>
              <UserFederationInfoLabel label={label} description={description} />
            </InfoGrid.Label>
            <InfoGrid.Value>{value}</InfoGrid.Value>
          </Fragment>
        ))}
      </InfoGrid>
    </div>
  );
};
