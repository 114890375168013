import { QueryGuard } from '../../../../../component/common/api/QueryGuard';
import { useUserRolesQuery } from '../../../user.queries';
import { IUserDetailView } from '../UserDetailView';
import { autoTableColumns, ConfirmationAction, Table } from '@usu/react-ui';
import { USER_ROLES_TABLE_COLUMNS } from '../../../user.config';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UsuButton } from '@usu/react-components';
import { UserAssignRolesDrawer } from '../../UserAssignRolesDrawer';
import { useDrawer } from '../../../../../support/hooks';
import { useUnassignClientRoleFromUserMutation } from '../../../user.mutations';

interface IUserRolesPanel extends IUserDetailView {}

export const UserRolesPanel = ({ user }: IUserRolesPanel) => {
  const { t } = useTranslation();
  const [present, dismiss] = useDrawer(UserAssignRolesDrawer, { user, dismiss: () => dismiss() });
  const userRolesQuery = useUserRolesQuery(user.id);
  const unassignClientRoleMutation = useUnassignClientRoleFromUserMutation();

  const columns = useMemo(
    () =>
      autoTableColumns({
        // @ts-ignore
        columns: USER_ROLES_TABLE_COLUMNS(t),
        actions: {
          actions: {
            cellMutator: (d) => (
              <ConfirmationAction
                title={t('users.unassign_role.confirm.title')}
                message={t('users.unassign_role.confirm.message')}
                onConfirm={() => {
                  unassignClientRoleMutation.mutate({
                    userId: user.id,
                    clientId: d.attributes.clientId,
                    roleName: d.attributes.name,
                  });
                }}
              >
                {({ open }) => (
                  <UsuButton color={'tertiary'} onClick={open}>
                    {t('general.unassign')}
                  </UsuButton>
                )}
              </ConfirmationAction>
            ),
          },
        },
      }),
    [t]
  );

  return (
    <QueryGuard query={userRolesQuery}>
      {{
        success: ({ query }) => {
          return (
            <Table
              id={`user-${user.id}-roles`}
              toolbar={
                <UsuButton size={'small'} onClick={present}>
                  {t('users.assign_role.label')}
                </UsuButton>
              }
              columns={columns}
              data={query.data?.data}
            />
          );
        },
      }}
    </QueryGuard>
  );
};
