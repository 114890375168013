export enum ImportType {
  Skip = 'skip',
  Overwrite = 'overwrite',
}

export interface DataImportSummary {
  clients: string[];
  groups: string[];
  users: string[];
}

export interface ImportFileContent {
  clients?: any[];
  groups?: any[];
  users?: any[];
}

export interface ImportLegacyFileContent {
  groups?: any[];
  users?: any[];
}
