import { UsuTag } from '@usu/react-components';
import { useTranslation } from 'react-i18next';
import { IUserDetailView } from './detail-view/UserDetailView';

interface IUserStateTag extends IUserDetailView {}

export const UserStateTag = ({ user }: IUserStateTag) => {
  const { t } = useTranslation();
  const { enabled, temporaryLockedInfo } = user.attributes;
  const { disabled } = temporaryLockedInfo;

  if (!disabled && enabled) return null;
  return (
    <UsuTag slot='info' className='mg-l-nm' color={disabled ? 'red' : 'yellow'} size={'small'}>
      {disabled ? t('users.state.locked').toUpperCase() : t('users.state.disabled').toUpperCase()}
    </UsuTag>
  );
};
