import { Role } from '../role.model';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { UsuButton, UsuButtonGroup, UsuCheckbox, UsuSearchInput } from '@usu/react-components';
import { FlexRow } from '../../../component/common/layout/Flex';
import { isNil } from '../../../support/util';

interface IAddRolesDrawer {
  roles: Role[];
  dismiss(): void;
  onSubmit(roles: string[]): void;
}

export const AddRolesDrawer = ({ roles, onSubmit, dismiss }: IAddRolesDrawer) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const selection = useRef<string[]>();
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    selection.current = selected;
  }, [selected]);

  const handleChange = (id: string, checked: boolean) => {
    setSelected((prevSelection) => (checked ? [...prevSelection, id] : prevSelection.filter((s) => s !== id)));
  };

  // TODO filter by client
  return (
    <>
      <UsuSearchInput
        className={'mg-b-lg'}
        style={{ width: '100%' }}
        placeholder={t('general.search_by_role_name')}
        value={search}
        onUsuSearch={(ev) => {
          const value = ((ev.detail.value as string) || '').trim();
          if (value && value.length > 0) {
            setSearch(value);
          }
        }}
        onUsuInput={(ev) => {
          if (search && ev.detail.value === '') {
            setSearch(undefined);
          }
        }}
      />
      {roles
        .filter((g) => {
          return search ? g.attributes.name.match(new RegExp(search, 'i')) !== null : true;
        })
        .map((item) => {
          return (
            <div key={item.id}>
              <UsuCheckbox onUsuChange={(e) => handleChange(item.attributes.name, e.detail.checked)}>
                {item.attributes.name}
              </UsuCheckbox>
            </div>
          );
        })}
      <FlexRow justifyContent={'flex-end'} className={'mg-t-lg'}>
        <UsuButtonGroup>
          <UsuButton color={'secondary'} onClick={dismiss}>
            {t('general.close')}
          </UsuButton>
          <UsuButton disabled={isNil(selection.current)} onClick={() => onSubmit(selection.current!)}>
            {t('general.assign')}
          </UsuButton>
        </UsuButtonGroup>
      </FlexRow>
    </>
  );
};
