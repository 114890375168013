export const toBoolean = (str: undefined | string) => /true/i.test(str || '');

/**
 * This file contains a number of helper function to help with typing or correctness,
 * since we'd like to avoid pulling in `lodash` or `ramda`. If this approach gets
 * out of hand, we'll pull on of the two in.
 */

/**
 * Returns wether `x` is exactly `null` or `undefined`
 */
export function isNil(x?: any): x is null | undefined {
  return x === null || x === undefined;
}

/**
 * Returns `true` if and only if `x` is a non-empty Array
 */
export function isEmpty(x?: null | any[] | readonly any[]) {
  return isNil(x) || !Array.isArray(x) || x.length === 0;
}

/**
 * Returns `true` if `x` is of type Function
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isFn(x?: any): x is Function {
  return isNil(x) ? false : typeof x === 'function';
}

/**
 * Returns the last item of an Array<T>, or `null` otherwise
 */
export function last<T = unknown>(x?: null | T[] | readonly T[]): T | null {
  return isEmpty(x) ? null : x![x!.length - 1];
}

export const camelToSnakeCase = (str: string) => str.replace(/([A-Z])/g, (m: string) => `_${m[0].toLowerCase()}`);
export const camelToDashCase = (str: string) => str.replace(/([A-Z])/g, (m: string) => `-${m[0].toLowerCase()}`);

export const copyToClipboard = async (value: string) => {
  await navigator.clipboard.writeText(value);
};

export const toMbAndRoundResult = (value: number): number => {
  return Number(Math.round(Number(value / 1048576 + 'e2')) + 'e-2');
};
