import { useUserFederationQuery } from '../../../../integration/integration.queries';
import { FlexCol, FlexRow } from '../../../../../component/common/layout/Flex';
import {
  UsuAvatar,
  UsuButton,
  UsuDropdownGroupTitle,
  UsuDropdownItem,
  UsuDropdownPanel,
  UsuIcon,
  UsuPopper,
  UsuProgressIndicator,
} from '@usu/react-components';
import { getAccountInitials, getAccountName } from '../../../../account/account.util';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { Fragment } from 'react';
import classNames from 'classnames';
import { IUserDetailView } from '../UserDetailView';
import { useTranslation } from 'react-i18next';
import { useInfo } from '../../../../realm/realm.queries';
import { ConfirmationAction } from '@usu/react-ui';
import {
  useDeleteUserMutation,
  useEnableOrDisableUserMutation,
  useSetRequiredUserActionsMutation,
  useSetUserEmailActionsMutation,
} from '../../../user.mutations';
import { UserEmailAction, UserRequiredAction } from '../../../user.model';
import { UserStateTag } from '../../UserStateTag';
import { useDrawer } from '../../../../../support/hooks';
import { ResetPasswordDrawer } from '../../ResetPasswordDrawer';

interface IUserHeaderInfo extends IUserDetailView {}

export const UserHeaderInfo = ({ user }: IUserHeaderInfo) => {
  const { t } = useTranslation();
  const { username, enabled, attributes, profilePictureUrl, userFederationId, requiredActions } = user.attributes;
  const [present, dismiss] = useDrawer(ResetPasswordDrawer, { user, dismiss: () => dismiss() });
  const jobTitle = attributes?.['job-title']?.[0];
  const infoQuery = useInfo();
  const userFederationQuery = useUserFederationQuery(userFederationId);
  const deleteMutation = useDeleteUserMutation();
  const enableOrDisableMutation = useEnableOrDisableUserMutation(!enabled);
  const requiredActionsMutation = useSetRequiredUserActionsMutation();
  const emailActionsMutation = useSetUserEmailActionsMutation();

  const info = [
    { label: t('general.username'), value: username },
    { label: t('users.fields.job_title'), value: jobTitle, classes: !jobTitle && 'no-data-text' },
    {
      label: t('users.fields.origin'),
      value: userFederationId ? userFederationQuery?.data?.data.attributes.name : t('general.keycloak'),
      loading: userFederationQuery?.isInitialLoading || userFederationQuery?.isFetching,
      classes: !userFederationId && 'no-data-text',
    },
  ];

  return (
    <FlexRow justifyContent={'space-between'}>
      <FlexRow alignItems={'center'}>
        <div className='mg-r-nm'>
          <UsuAvatar
            style={{ width: '120px', height: '120px', fontSize: '42px' }}
            initials={getAccountInitials(user)}
            image={profilePictureUrl}
          />
        </div>
        <FlexCol>
          <FlexRow alignItems={'center'}>
            <h2>{getAccountName(user)}</h2>
            <UserStateTag user={user} />
          </FlexRow>
          <InfoGrid>
            {info.map(({ label, value, loading, classes }) => (
              <Fragment key={label}>
                <InfoGrid.Label>{label}:</InfoGrid.Label>
                <InfoGrid.Value>
                  {loading ? (
                    <UsuProgressIndicator shape='circle' infinite size={'small'} />
                  ) : (
                    <span className={classNames(classes)}>{value ?? t('general.not_set')}</span>
                  )}
                </InfoGrid.Value>
              </Fragment>
            ))}
          </InfoGrid>
        </FlexCol>
      </FlexRow>
      <FlexRow style={{ height: 'fit-content' }}>
        <UsuPopper>
          <UsuButton slot={'anchor'} size={'small'} color={'secondary'} shape={'circle'}>
            <UsuIcon type={'more'} />
          </UsuButton>
          <UsuDropdownPanel slot={'content'}>
            <UsuDropdownGroupTitle>{t('users.dropdown.actions.title')}</UsuDropdownGroupTitle>
            <UsuDropdownItem onUsuClick={present}>
              <UsuIcon slot={'icon-before'} type={'reset'} size={'small'} />
              {t('users.dropdown.actions.reset_password')}
            </UsuDropdownItem>
            <UsuDropdownItem
              disabled={requiredActions.includes(UserRequiredAction.UPDATE_PASSWORD)}
              onUsuClick={() =>
                requiredActionsMutation.mutate({ userId: user.id, actions: [UserRequiredAction.UPDATE_PASSWORD] })
              }
            >
              <UsuIcon slot={'icon-before'} type={'add'} size={'small'} />
              {t('users.dropdown.actions.force_update_password')}
            </UsuDropdownItem>
            {infoQuery.data?.attributes.mailServerConnected && (
              <UsuDropdownItem
                onUsuClick={() =>
                  emailActionsMutation.mutate({ userId: user.id, actions: [UserEmailAction.UPDATE_PASSWORD] })
                }
              >
                <UsuIcon slot={'icon-before'} type={'mail'} size={'small'} />
                {t('users.dropdown.actions.reset_password')}
              </UsuDropdownItem>
            )}
            {infoQuery.data?.attributes.mailServerConnected && (
              <UsuDropdownItem
                onUsuClick={() =>
                  emailActionsMutation.mutate({ userId: user.id, actions: [UserEmailAction.VERIFY_EMAIL] })
                }
              >
                <UsuIcon slot={'icon-before'} type={'mail'} size={'small'} />
                {t('users.dropdown.actions.verify_email')}
              </UsuDropdownItem>
            )}
            <UsuDropdownGroupTitle className={'mg-t-sm'}>{t('users.dropdown.general.title')}</UsuDropdownGroupTitle>
            <ConfirmationAction
              title={enabled ? t('users.disable.confirm.title') : t('users.enable.confirm.title')}
              message={enabled ? t('users.disable.confirm.message') : t('users.enable.confirm.message')}
              onConfirm={() => enableOrDisableMutation.mutate({ userId: user.id })}
            >
              {({ open }) => (
                <UsuDropdownItem onClick={open}>
                  <UsuIcon slot={'icon-before'} type={enabled ? 'lock-locked' : 'lock-unlocked'} size={'small'} />
                  {enabled ? t('users.dropdown.general.disable_user') : t('users.dropdown.general.enable_user')}
                </UsuDropdownItem>
              )}
            </ConfirmationAction>
            <ConfirmationAction
              title={t('users.delete.confirm.title')}
              message={t('users.delete.confirm.message')}
              onConfirm={() => deleteMutation.mutate({ userId: user.id })}
            >
              {({ open }) => (
                <UsuDropdownItem onClick={open}>
                  <UsuIcon slot={'icon-before'} type={'delete'} size={'small'} />
                  {t('users.dropdown.general.delete_user')}
                </UsuDropdownItem>
              )}
            </ConfirmationAction>
          </UsuDropdownPanel>
        </UsuPopper>
      </FlexRow>
    </FlexRow>
  );
};
