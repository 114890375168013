import { PageProps } from '../../config/routes';
import { Page } from '../common/layout/Page';
import { GroupsList } from '../../domain/group/component/GroupsList';

export const Groups = ({ page }: PageProps) => {
  return (
    <Page heading={page.title}>
      <GroupsList />
    </Page>
  );
};
