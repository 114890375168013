import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../assets/locales/en.json';

if (module.hot) {
  // 'module' refers to node's module system, it's predefined
  module.hot.accept();
}

type Obj = { [key: string]: string | object };

/**
 * Removes each key with the value of const nono. The translator then uses a fallback language or if that fails the key itself.
 * @param language
 */
const cleanOutNotTranslated = (language: Obj) => {
  const nono = '__STRING_NOT_TRANSLATED__';
  const cleanLevel = (level: Obj) => {
    Object.keys(level).forEach((key) => {
      if (typeof level[key] === 'string') {
        if (level[key] === nono) {
          delete level[key];
        }
      }
      if (typeof level[key] === 'object') {
        level[key] = cleanLevel(level[key] as Obj);
      }
    });
    return level;
  };
  return cleanLevel(language);
};

export const resources = {
  en: { translation: cleanOutNotTranslated(en) },
} as const;

i18n
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
