import { ComponentClass, createElement, FunctionComponent } from 'react';
import { createBrowserRouter, RouteObject, useNavigate } from 'react-router-dom';
import { isEmpty, isNil } from '../support/util';
import { ENV } from '../support/env';
import { Layout, LayoutErrorPageNotFound } from '../component/app/layout/Layout';
import { Clients } from '../component/page/Clients';
import { Dashboard } from '../component/page/Dashboard';
import { Groups } from '../component/page/Groups';
import { Integrations } from '../component/page/Integrations';
import { Users } from '../component/page/Users';
import { Settings } from '../component/page/Settings';
import { PAGE_IDS } from './paths';

/**
 * The application's full page/route tree. This custom structure is used for routing (via `react-router-dom`) as well as
 * menus (see `component/structural/layout/LayoutSidebar`) and page metadata (see `component/structural/Page.tsx`).
 *
 * A router instance is created below via a mapper function `routeFromPage`.
 */
export const pages: RouteItem[] = [
  {
    id: PAGE_IDS.dashboard,
    title: 'page.dashboard.title',
    component: Dashboard,
    path: '/',
    menu: { title: 'page.dashboard.menu.title', icon: 'dashboard' },
  },
  {
    id: PAGE_IDS.groups,
    title: 'page.groups.title',
    component: Groups,
    path: '/groups',
    menu: { title: 'page.groups.menu.title', icon: 'users-group' },
  },
  {
    id: PAGE_IDS.users,
    title: 'page.users.title',
    component: Users,
    path: '/users',
    menu: { title: 'page.users.menu.title', icon: 'users-user' },
  },
  {
    id: PAGE_IDS.clients,
    title: 'page.clients.title',
    component: Clients,
    path: '/clients',
    menu: { title: 'page.clients.menu.title', icon: 'component' },
  },
  {
    id: PAGE_IDS.integrations,
    title: 'page.integrations.title',
    component: Integrations,
    path: '/integrations',
    menu: { title: 'page.integrations.menu.title', icon: 'it-infrastructure' },
  },
  {
    id: PAGE_IDS.settings,
    title: 'page.settings.title',
    component: Settings,
    path: '/settings',
    menu: { title: 'page.settings.menu.title', icon: 'settings' },
  },
];

export const pageRouter = createBrowserRouter(
  [
    {
      id: '$', // virtual root route id
      path: '/',
      element: createElement(Layout),
      errorElement: createElement(LayoutErrorPageNotFound),
      children: pages.map((page) => routeFromPage(page)),
    },
  ],
  {
    basename: ENV.BASENAME,
  }
);

/**
 * Creates a `RouteObject` tree from a `PageItem` and an optional parent. Both are passed as props
 * to the underlying component (see `PageProps`), which is why the `PageItem` definition uses
 * a `component` instead of an already-rendered `element`.
 */
function routeFromPage(page: RouteItem, parentPages?: RouteItem[]): RouteObject {
  const hasNoParents = isEmpty(parentPages);
  const joinedPath = hasNoParents ? page.path : `${parentPages!.map((p) => p.path).join('')}${page.path}`;
  const path = joinedPath.replace(/\/+/, '/');

  const id = hasNoParents ? page.id : `${parentPages!.map((p) => (p as PageItem).id).join('.')}.${page.id}`;
  const element = createElement(page.component, {
    page: { ...page, path },
    parentPages: parentPages as PageItem[],
    // include path as React key to at least force rendering when the path pattern changes
    // this doesn't solve _all_ re-rendering issues, but a bunch of them
    key: path,
  });
  const children = page.children?.map((child) => routeFromPage(child, hasNoParents ? [page] : [...parentPages!, page]));

  return {
    id,
    path,
    element,
    children,
  };
}

/**
 * Base props for page/route components (see `component/page/...`).
 */
export interface PageProps {
  page: PageItem;
  parentPages?: PageItem[];
}

export type RouteItem = PageItem;

export interface PageItem {
  id: string;
  title: string;
  component: string | FunctionComponent<PageProps> | ComponentClass<PageProps>;
  path: string;
  children?: RouteItem[];
  menu?: {
    title: string;
    icon?: string;
  };
}

type NavPage = { id: string };

export const useNav = () => {
  const nav = useNavigate();

  return (page: NavPage) => {
    const { id } = page;
    const path = pathFromPageId(id);
    if (isNil(path)) {
      throw new Error(`Path for route id '${id}' not found`);
    }
    // strip trailing slash
    nav(path.replace(/\/$/, ''));
  };
};

const joinPaths = (...pages: RouteItem[]) => pages.map((p) => p.path).join('');

const getPath = (id: string, page: RouteItem, parents?: PageItem[]): undefined | string => {
  const all = [...(parents ?? []), page];
  if (page.id === id) return joinPaths(...all);
  for (const child of page.children ?? []) {
    const path = getPath(id, child, all);
    if (path) return path;
  }
};

const pathFromPageId = (id: string) => {
  for (const page of pages) {
    const path = getPath(id, page);
    if (path) return path;
  }
};
