import { API_PATHS } from '../../config/paths';
import { useApiQuery } from '../../support/api';
import { ApiPasswordPolicyData } from './password-policy.model';

export const usePasswordPoliciesQuery = () => {
  return useApiQuery(['password-policies'], {
    queryFn: (http, ctx) =>
      http
        .get<ApiPasswordPolicyData>(API_PATHS.realm.passwordPolicies, { signal: ctx.signal })
        .then((res) => res.data.data),
  });
};
