import {
  ApiEntityData,
  ApiPage,
  getQueryParamsFromKey,
  normalizePagedQueryParams,
  useApiQuery,
  usePagedApiQuery,
} from '../../support/api';
import { API_PATHS } from '../../config/paths';
import { User } from './user.model';
import { Group } from '../group/group.model';
import { Role } from '../role/role.model';

export const USERS_QUERY_KEY = 'users';

export const useUsersQuery = () => {
  return usePagedApiQuery((params) => [USERS_QUERY_KEY, normalizePagedQueryParams(params)], {
    queryFn: (http, ctx) => {
      const params = getQueryParamsFromKey(ctx.queryKey);
      return http.get<ApiPage<User>>(API_PATHS.user.list, { signal: ctx.signal, params }).then((res) => res.data);
    },
  });
};

export const useUserGroupsQuery = (id: string) => {
  return useApiQuery([USERS_QUERY_KEY, id, 'groups'], {
    queryFn: (http, ctx) =>
      http.get<ApiEntityData<Group[]>>(API_PATHS.user.group.list(id), { signal: ctx.signal }).then((res) => res.data),
  });
};

export const useUserAvailableGroupsQuery = (id: string) => {
  return useApiQuery([USERS_QUERY_KEY, id, 'groups', 'available'], {
    queryFn: (http, ctx) =>
      http
        .get<ApiEntityData<Group[]>>(API_PATHS.user.group.available(id), { signal: ctx.signal })
        .then((res) => res.data),
  });
};

export const useUserRolesQuery = (id: string) => {
  return useApiQuery([USERS_QUERY_KEY, id, 'roles', 'effective'], {
    queryFn: (http, ctx) =>
      http.get<ApiEntityData<Role[]>>(API_PATHS.user.role.list(id), { signal: ctx.signal }).then((res) => res.data),
  });
};

export const useUserAvailableRolesQuery = (id: string) => {
  return useApiQuery([USERS_QUERY_KEY, id, 'roles', 'available'], {
    queryFn: (http, ctx) =>
      http
        .get<ApiEntityData<Role[]>>(API_PATHS.user.role.available(id), { signal: ctx.signal })
        .then((res) => res.data),
  });
};
