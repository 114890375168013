import {
  ApiEntityData,
  ApiPage,
  getQueryParamsFromKey,
  IApiPageState,
  normalizePagedQueryParams,
  useApiQuery,
  usePagedApiQuery,
} from '../../support/api';
import { API_PATHS } from '../../config/paths';
import { Client } from './client.model';
import { Role } from '../role/role.model';

export const CLIENTS_QUERY_KEY = 'clients';

export const useClientsQuery = (defaultParams: Partial<IApiPageState> = {}) => {
  return usePagedApiQuery((params) => [CLIENTS_QUERY_KEY, normalizePagedQueryParams(params, defaultParams)], {
    queryFn: (http, ctx) => {
      const params = getQueryParamsFromKey(ctx.queryKey);
      return http.get<ApiPage<Client>>(API_PATHS.client.list, { signal: ctx.signal, params }).then((res) => res.data);
    },
  });
};

export const useClientRolesQuery = (id: string) => {
  return useApiQuery([CLIENTS_QUERY_KEY, id, 'roles'], {
    queryFn: (http, ctx) =>
      http.get<ApiEntityData<Role[]>>(API_PATHS.client.role.list(id), { signal: ctx.signal }).then((res) => res.data),
  });
};
