import { RouterProvider } from 'react-router-dom';
import { UsuApp } from '@usu/react-components';
import { pageRouter } from '../../config/routes';
import { AppProvider } from './AppProvider';

export const App = () => {
  return (
    <AppProvider>
      <UsuApp>
        <RouterProvider router={pageRouter} />
      </UsuApp>
    </AppProvider>
  );
};
