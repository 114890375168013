import { ReactElement, useMemo } from 'react';
import { AxiosError } from 'axios';
import { AuthContextProps, useAuth } from 'oidc-react';
import { isNil } from '../../../support/util';
import { useAccountQuery } from '../account.queries';
import { AccountData } from '../account.model';

interface IAccountGuard {
  loading?: ReactElement;
  forbidden?: ReactElement;
  unresolved?: ReactElement;
  children: (auth: Required<AuthContextProps>, account: AccountData) => ReactElement;
}

export const AccountGuard = ({ loading, forbidden, unresolved, children }: IAccountGuard) => {
  const auth = useAuth();
  const query = useAccountQuery({ enabled: !auth.isLoading && !isNil(auth.userData) });

  return useMemo(() => {
    if (auth.isLoading || query.isLoading) {
      return loading || null;
    } else if (auth.userData?.expired || isNil(auth.userData) || query.isError || isNil(query.data)) {
      if (query.error instanceof AxiosError && query.error.response?.status === 403) {
        return forbidden || null;
      }
      return unresolved || null;
    } else {
      return children(auth as Required<AuthContextProps>, query.data);
    }
  }, [auth.userData, auth.isLoading, query.isError, query.isLoading, query.data, loading, unresolved]);
};
