import { ApiMutationFn, useApiMutation } from '../../support/api';
import { HttpResponse } from '../../support/http';
import { API_PATHS } from '../../config/paths';
import { DataImportSummary, ImportFileContent, ImportLegacyFileContent, ImportType } from './import.model';
import { useTranslation } from 'react-i18next';
import { useUsuToast } from '@usu/react-components';
import { useQueryClient } from '@tanstack/react-query';
import { CLIENTS_QUERY_KEY } from '../client/client.queries';
import { USERS_QUERY_KEY } from '../user/user.queries';
import { GROUPS_QUERY_KEY } from '../group/group.queries';

const importMutation: ApiMutationFn<HttpResponse<DataImportSummary>, { type: ImportType } & ImportFileContent> = (
  http,
  { type, ...payload }
) => {
  const queryString = new URLSearchParams({ type }).toString();
  return http.post<DataImportSummary>(`${API_PATHS.import}?${queryString}`, payload);
};

export const useImportMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(importMutation, {
    onSuccess: (res, { clients, groups, users }) => {
      if (clients) queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY_KEY] }).catch();
      if (groups) queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY] }).catch();
      if (users) queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      const c = `${clients?.length}/${res.data.clients.length} ${t('general.clients')}`;
      const g = `${groups?.length}/${res.data.groups.length} ${t('general.groups')}`;
      const u = `${users?.length}/${res.data.users.length} ${t('general.users')}`;
      const summary = [...(clients ? [c] : []), ...(groups ? [g] : []), ...(users ? [u] : [])].join(', ');
      toast.show({
        status: 'success',
        header: t('import.success', { summary }),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('import.error'),
      });
    },
  });
};

const importLegacyMutation: ApiMutationFn<
  HttpResponse<DataImportSummary>,
  { clientId: string; password: string; temporary: boolean } & ImportLegacyFileContent
> = (http, payload) => http.post<DataImportSummary>(API_PATHS.importLegacy, payload);

export const useImportLegacyMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(importLegacyMutation, {
    onSuccess: (res, { groups, users }) => {
      // update clients because groups are in fact client roles...
      if (groups) queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY_KEY] }).catch();
      if (users) queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] }).catch();
      const summary = t('general.data').toLowerCase();
      toast.show({
        status: 'success',
        header: t('import.success', { summary }),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('import.error'),
      });
    },
  });
};
