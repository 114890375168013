import { ComponentClass, createElement, FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import { UsuButton, UsuDataMessage, UsuProgressIndicator } from '@usu/react-components';
import { isNil } from '../../../support/util';
import { FlexRow } from '../layout/Flex';
import { Timeout } from '../misc/Timeout';
import { NoDataPanel } from '../misc/NoDataPanel';

export interface IQueryGuard<T = unknown, E = unknown> {
  query: UseQueryResult<T, E>;
  children: Partial<Record<QueryStatus, QueryChild<T, E>>>;
}

export function QueryGuard<T, E>(props: IQueryGuard<T, E>) {
  const { query, children } = props;

  const status = queryStatus(query);
  const child = useMemo(
    () =>
      queryChild(status, {
        ...children,
        ...(!children.initializing ? { initializing: QueryGuard.DefaultInitializing } : {}),
        ...(!children.error ? { error: QueryGuard.DefaultError } : {}),
        ...(!children.empty ? { empty: QueryGuard.DefaultEmpty } : {}),
      }),
    [status]
  );

  return useMemo(() => (isNil(child) ? null : createElement(child, { query, key: status })), [child, query.data]);
}

type QueryStatus = Exclude<UseQueryResult['status'], 'loading'> | 'refetching' | 'initializing' | 'empty';

interface IQueryChild<T, E> {
  query: UseQueryResult<T, E>;
}

type QueryChild<T, E> = FunctionComponent<IQueryChild<T, E>> | ComponentClass<IQueryChild<T, E>>;

function queryStatus(query: UseQueryResult): QueryStatus {
  if (query.isInitialLoading) {
    return 'initializing';
  } else if (query.isRefetching) {
    return 'refetching';
  } else if (query.isError) {
    return 'error';
  } else if (isNil(query.data)) {
    return 'empty';
  } else {
    return 'success';
  }
}

/**
 * Returns the matching child element for the passed QueryStatus. If no such key is present, and
 * the status is either 'refetching' or 'empty', it will return the 'success' child.
 */
function queryChild<T, E>(status: QueryStatus, children: IQueryGuard<T, E>['children']): QueryChild<T, E> | null {
  const child = children[status];

  if (isNil(child)) {
    const success = children['success'];
    if ((status === 'refetching' || status === 'empty') && !isNil(success)) {
      return success;
    }
    return null;
  }

  return child;
}

QueryGuard.DefaultInitializing = function DefaultInitializing<T, E>(props: IQueryChild<T, E>) {
  return (
    <Timeout whenExpired={<QueryGuard.DefaultError {...props} />}>
      <FlexRow justifyContent={'center'}>
        <UsuProgressIndicator shape={'circle'} size={'small'} infinite hidePercentage />
      </FlexRow>
    </Timeout>
  );
};

QueryGuard.DefaultError = function DefaultError<T, E>(props: IQueryChild<T, E>) {
  const { t } = useTranslation();

  return (
    <UsuDataMessage status={'error'}>
      <span slot={'title'}>{t('general.api.error.title')}</span>
      <span slot={'message'}>{t('general.api.error.message')}</span>
      <div slot={'action'}>
        <UsuButton
          disabled={props.query.isLoading}
          onClick={() => {
            props.query
              .refetch({
                cancelRefetch: true,
                stale: true,
              })
              .catch();
          }}
        >
          {t('general.api.error.retry')}
        </UsuButton>
      </div>
    </UsuDataMessage>
  );
};

QueryGuard.DefaultEmpty = function DefaultEmpty<T, E>(_props: IQueryChild<T, E>) {
  return <NoDataPanel />;
};
