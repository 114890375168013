import { useClientsQuery } from '../client.queries';
import { useTranslation } from 'react-i18next';
import { MasterDetailView } from '../../../component/common/misc/MasterDetailView';
import { FlexRow } from '../../../component/common/layout/Flex';
import { UsuPaginator, UsuSearchInput } from '@usu/react-components';
import { ClientListItem } from './ClientListItem';
import { ClientDetailView } from './detail-view/ClientDetailView';
import { isNil } from '../../../support/util';

export const ClientsList = () => {
  const { t } = useTranslation();
  const [clientsData, params, goToPage, updateParams] = useClientsQuery();

  return (
    <MasterDetailView
      data={clientsData.isLoading || clientsData.isRefetching || isNil(clientsData.data) ? [] : clientsData.data.data}
      toolbar={
        <FlexRow className={'mg-b-nm'}>
          <UsuSearchInput
            placeholder={t('users.search.placeholder')}
            value={params.search}
            onUsuSearch={(ev) => {
              const search = ((ev.detail.value as string) || '').trim();
              if (search && search.length > 0) {
                updateParams({ search });
              }
            }}
            onUsuInput={(ev) => {
              if (params.search && ev.detail.value === '') {
                updateParams({ search: undefined });
              }
            }}
          />
        </FlexRow>
      }
      renderItem={({ item: client, isSelected, setSelected }) => (
        <ClientListItem key={client.id} client={client} selected={isSelected} onClick={() => setSelected(client)} />
      )}
      renderDetail={(selected) => <ClientDetailView key={selected.id} client={selected} />}
      footer={
        <FlexRow className={'pd-t-nm'} justifyContent={'center'}>
          <UsuPaginator
            min={1}
            max={params.totalPages || 1}
            current={params.page || 1}
            onUsuChange={(e) => goToPage(e.detail.currentPage)}
          />
        </FlexRow>
      }
    />
  );
};
