import { useUserGroupsQuery } from '../../../user.queries';
import { QueryGuard } from '../../../../../component/common/api/QueryGuard';
import { IUserDetailView } from '../UserDetailView';
import { useMemo } from 'react';
import { autoTableColumns, ConfirmationAction, Table } from '@usu/react-ui';
import { USER_GROUPS_TABLE_COLUMNS } from '../../../user.config';
import { UsuButton } from '@usu/react-components';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../../../../support/hooks';
import { UserJoinGroupsDrawer } from '../../UserJoinGroupsDrawer';
import { useRemoveUserFromGroupMutation } from '../../../../group/group.mutations';

interface IUserGroupsPanel extends IUserDetailView {}

export const UserGroupsPanel = ({ user }: IUserGroupsPanel) => {
  const { t } = useTranslation();
  const [present, dismiss] = useDrawer(UserJoinGroupsDrawer, { user, dismiss: () => dismiss() });
  const userGroupsQuery = useUserGroupsQuery(user.id);
  const removeUserFormGroupMutation = useRemoveUserFromGroupMutation();

  const columns = useMemo(
    () =>
      autoTableColumns({
        // @ts-ignore
        columns: USER_GROUPS_TABLE_COLUMNS(t),
        actions: {
          actions: {
            cellMutator: (d) => (
              <ConfirmationAction
                title={t('users.remove_group.confirm.title')}
                message={t('users.remove_group.confirm.message')}
                onConfirm={() => removeUserFormGroupMutation.mutate({ groupId: d.id, userId: user.id })}
              >
                {({ open }) => (
                  <UsuButton color={'tertiary'} onClick={open}>
                    {t('general.leave')}
                  </UsuButton>
                )}
              </ConfirmationAction>
            ),
          },
        },
      }),
    [t, user]
  );

  return (
    <QueryGuard query={userGroupsQuery}>
      {{
        success: ({ query }) => {
          return (
            <Table
              id={`user-${user.id}-groups`}
              toolbar={
                <UsuButton size={'small'} onClick={present}>
                  {t('users.join_group.label')}
                </UsuButton>
              }
              columns={columns}
              data={query.data?.data}
            />
          );
        },
      }}
    </QueryGuard>
  );
};
