export function transformFileToJson<T extends object>(file: Blob): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.addEventListener('load', (event) => {
      const content = event?.target?.result as string;
      const json: T = JSON.parse(content);
      resolve(json);
      // const validCheck =
      //   field === 'suggestions'
      //     ? administrativeValidateOptimizationSuggestions(json)
      //     : administrativeValidateSimulationAttributes(json);
      // if (!validCheck.valid) {
      //   // @ts-ignore
      //   reject({ field, message: validCheck.message });
      // } else {
      //   // @ts-ignore
      //   resolve(validCheck.content);
      // }
    });
    reader.addEventListener('error', reject);
  });
}
