import { Dispatch, ReactNode, SetStateAction, useEffect, useState, CSSProperties } from 'react';
import { UsuSplitView } from '@usu/react-components';

interface Identifiable {
  id: string | number;
}

interface RenderItemProps<T> {
  item: T;
  isSelected: boolean;
  setSelected: Dispatch<SetStateAction<T | undefined>>;
}

interface IMasterDetailView<T extends Identifiable> {
  data: T[];
  toolbar?: ReactNode;
  footer?: ReactNode;
  renderItem: (props: RenderItemProps<T>) => ReactNode;
  renderDetail: (item: T) => ReactNode;
}

export const getListItemStyle = (selected: undefined | boolean): CSSProperties => ({
  borderLeftWidth: 4,
  borderLeftStyle: 'solid',
  borderLeftColor: selected ? 'var(--usu-color-primary-usu-blue-base)' : 'transparent',
});

export const MasterDetailView = <T extends Identifiable>({
  data,
  toolbar,
  footer,
  renderItem,
  renderDetail,
}: IMasterDetailView<T>) => {
  const [selected, setSelected] = useState<undefined | T>(undefined);

  useEffect(() => setSelected(data[0]), [data]);

  return (
    <UsuSplitView percentage={30} leftMinPx={350} rightMinPx={400} className={'pd-b-md'} style={{ overflow: 'hidden' }}>
      <aside
        slot={'left'}
        className={'pd-r-nm'}
        style={{
          overflow: 'hidden',
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        {toolbar}
        <section style={{ overflow: 'hidden auto' }}>
          {data.map((item) => {
            return renderItem({ item, isSelected: selected?.id === item.id, setSelected });
          })}
        </section>
        {footer}
      </aside>
      <main
        slot={'right'}
        className={'pd-l-nm'}
        style={{
          overflow: 'hidden',
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto',
        }}
      >
        {selected && renderDetail(selected)}
      </main>
    </UsuSplitView>
  );
};
