import { AccountData, AccountRequest } from './account.model';
import { camelToDashCase, isNil } from '../../support/util';

export const getAccountName = (account: AccountData) => {
  const { username, firstname, lastname } = account.attributes;
  if (isNil(firstname)) {
    return username;
  }
  return `${firstname} ${lastname ?? ''}`;
};

export const getAccountInitials = (account: AccountData) => {
  const { username, firstname, lastname } = account.attributes;
  return (firstname?.[0] ?? username[0]) + (lastname?.[0] ?? '');
};

// export const toAccountRequest = (account: AccountData, change: object): AccountRequest => {
//   const { username, email, firstname, lastname, attributes } = account.attributes;
//   const reqBody = { username, email, firstname, lastname, attributes };
//   Object.entries(change).forEach(([key, value]) => {
//     reqBody.attributes[camelToDashCase(key)] = [value];
//   });
//   return reqBody;
// };
