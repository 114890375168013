import { PageProps } from '../../config/routes';
import { Page } from '../common/layout/Page';
import { UsersList } from '../../domain/user/component/UsersList';

export const Users = ({ page }: PageProps) => {
  return (
    <Page
      heading={page.title}
      style={{
        overflow: 'hidden',
        height: 'var(--usu-page-fullscreen-height)',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
      }}
    >
      <UsersList />
    </Page>
  );
};
