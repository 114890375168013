import { useCallback, useState } from 'react';
import type { TreeNodeData } from './tree.model';

export type TreeExpandedState = Record<string, boolean>;

function getInitialExpandedState(initialState: TreeExpandedState, data: TreeNodeData[], acc: TreeExpandedState = {}) {
  data.forEach((node) => {
    acc[node.value] = node.value in initialState ? initialState[node.value] : false;

    if (Array.isArray(node.children)) {
      getInitialExpandedState(initialState, node.children, acc);
    }
  });

  return acc;
}

export interface UseTreeInput {
  /** Initial expanded state of all nodes */
  initialExpandedState?: TreeExpandedState;
}

export interface UseTreeReturnType {
  /** A record of `node.value` and boolean values that represent nodes expanded state */
  expandedState: TreeExpandedState;

  /** Initializes tree state based on provided data, called automatically by the Tree component */
  initialize: (data: TreeNodeData[]) => void;

  /** Toggles expanded state of the node with provided value */
  toggleExpanded: (value: string) => void;

  /** Collapses node with provided value */
  collapse: (value: string) => void;

  /** Expands node with provided value */
  expand: (value: string) => void;

  /** Expands all nodes */
  expandAllNodes: () => void;

  /** Collapses all nodes */
  collapseAllNodes: () => void;

  /** Sets expanded state */
  setExpandedState: React.Dispatch<React.SetStateAction<TreeExpandedState>>;

  /** A value of the node that is currently hovered */
  hoveredNode: string | null;

  /** Sets hovered node */
  setHoveredNode: React.Dispatch<React.SetStateAction<string | null>>;
}

export function useTree({ initialExpandedState = {} }: UseTreeInput = {}): UseTreeReturnType {
  const [expandedState, setExpandedState] = useState(initialExpandedState);
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);

  const initialize = useCallback((_data: TreeNodeData[]) => {
    setExpandedState((current) => getInitialExpandedState(current, _data));
  }, []);

  const toggleExpanded = useCallback((value: string) => {
    setExpandedState((current) => ({ ...current, [value]: !current[value] }));
  }, []);

  const collapse = useCallback((value: string) => {
    setExpandedState((current) => ({ ...current, [value]: false }));
  }, []);

  const expand = useCallback((value: string) => {
    setExpandedState((current) => ({ ...current, [value]: true }));
  }, []);

  const expandAllNodes = useCallback(() => {
    setExpandedState((current) => {
      const next = { ...current };
      Object.keys(next).forEach((key) => {
        next[key] = true;
      });

      return next;
    });
  }, []);

  const collapseAllNodes = useCallback(() => {
    setExpandedState((current) => {
      const next = { ...current };
      Object.keys(next).forEach((key) => {
        next[key] = false;
      });

      return next;
    });
  }, []);

  return {
    expandedState,
    initialize,

    toggleExpanded,
    collapse,
    expand,
    expandAllNodes,
    collapseAllNodes,
    setExpandedState,

    hoveredNode,
    setHoveredNode,
  };
}

export type TreeController = ReturnType<typeof useTree>;
