import { PageProps } from '../../config/routes';
import { Page } from '../common/layout/Page';
import { UserFederationsList } from '../../domain/integration/component/UserFederationsList';

export const Integrations = ({ page }: PageProps) => {
  return (
    <Page heading={page.title}>
      <UserFederationsList />
    </Page>
  );
};
