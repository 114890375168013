import { useTranslation } from 'react-i18next';
import { User } from 'oidc-client-ts';
import {
  useUsuModal,
  UsuDropdownDivider,
  UsuDropdownItem,
  UsuHeader,
  UsuHeaderMenu,
  UsuIcon,
} from '@usu/react-components';
import { AccountData } from '../../../domain/account/account.model';
import { getAccountName } from '../../../domain/account/account.util';
import { AboutDialog } from '../../common/misc/AboutDialog';
import { DATA_PRIVACY_URL, ENV, LEGAL_NOTICE_URL } from '../../../support/env';

// @ts-ignore
import USULogo from '../../../assets/usu.svg';
// @ts-ignore
import ServiceLogo from '../../../assets/service_logo.svg';

type ILayoutHeader =
  | {
      initialized?: false;
      accessDenied?: false;
    }
  | {
      initialized: true;
      accessDenied?: false;
      account: AccountData;
      onLogoutClick: () => Promise<void>;
    }
  | {
      initialized: true;
      accessDenied: true;
      account: User;
      onLogoutClick: () => Promise<void>;
    };

export function LayoutHeader(props: ILayoutHeader) {
  const { t } = useTranslation();
  const [present] = useUsuModal(AboutDialog);

  return (
    <UsuHeader slot={'header'} logoUrl={USULogo} ownAppLogo={ServiceLogo} appName={'User Management'}>
      {props.initialized && (
        <UsuHeaderMenu
          slot={'menu'}
          userName={props.accessDenied ? props.account.profile.name : getAccountName(props.account)}
          userImage={!props.accessDenied ? props.account.attributes.profilePictureUrl : undefined}
        >
          {!props.accessDenied && (
            <>
              {(ENV.UM_ACCOUNT_URL || '').length > 0 && (
                <UsuDropdownItem onUsuClick={() => window.open(ENV.UM_ACCOUNT_URL, '_blank')?.focus()}>
                  <UsuIcon type={'users-user'} size={'small'} slot={'icon-before'} /> {t('general.profile')}
                </UsuDropdownItem>
              )}
              <UsuDropdownItem onUsuClick={() => present()}>
                <UsuIcon type={'information'} size={'small'} slot={'icon-before'} /> {t('general.about')}
              </UsuDropdownItem>
              <UsuDropdownItem onUsuClick={() => window.open(LEGAL_NOTICE_URL, '_blank')}>
                <UsuIcon type={'secure'} size={'small'} slot={'icon-before'} /> {t('general.legal_notice')}
              </UsuDropdownItem>
              <UsuDropdownItem onUsuClick={() => window.open(DATA_PRIVACY_URL, '_blank')}>
                <UsuIcon type={'compliance'} size={'small'} slot={'icon-before'} /> {t('general.data_privacy')}
              </UsuDropdownItem>
              <UsuDropdownDivider extend />
            </>
          )}
          <UsuDropdownItem onUsuClick={() => props.onLogoutClick().catch()}>
            <UsuIcon type={'logout'} size={'small'} slot={'icon-before'} /> {t('general.account.logout')}
          </UsuDropdownItem>
        </UsuHeaderMenu>
      )}
    </UsuHeader>
  );
}
