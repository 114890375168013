import { Fragment } from 'react';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import { UserFederation } from '../../../integration.model';
import { useTranslation } from 'react-i18next';
import { UserFederationInfoLabel } from './UserFederationInfoLabel';

interface IUserFederationKerberosPanel {
  userFederation: UserFederation;
}

export const UserFederationKerberosPanel = ({ userFederation }: IUserFederationKerberosPanel) => {
  const { t } = useTranslation();
  const { kerberos } = userFederation.attributes;
  const { allowKerberosAuthentication, useKerberosForPasswordAuthentication } = kerberos;

  const info = [
    {
      label: t('integrations.allow_kerberos_authentication.label'),
      value: allowKerberosAuthentication ? t('general.yes') : t('general.no'),
      description: t('integrations.allow_kerberos_authentication.description'),
    },
    {
      label: t('integrations.use_kerberos_password_authentication.label'),
      value: useKerberosForPasswordAuthentication ? t('general.yes') : t('general.no'),
      description: t('integrations.use_kerberos_password_authentication.description'),
    },
  ];

  return (
    <div>
      <InfoGrid>
        {info.map(({ label, value, description }) => (
          <Fragment key={label}>
            <InfoGrid.Label>
              <UserFederationInfoLabel label={label} description={description} />
            </InfoGrid.Label>
            <InfoGrid.Value>{value}</InfoGrid.Value>
          </Fragment>
        ))}
      </InfoGrid>
    </div>
  );
};
