import { useUserAvailableRolesQuery } from '../user.queries';
import { User } from '../user.model';
import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { useTranslation } from 'react-i18next';
import { useAssignClientRoleToUserMutation } from '../user.mutations';
import { AddRolesDrawer } from '../../role/component/AddRolesDrawer';

interface IUserAssignRolesDrawer {
  user: User;
  dismiss(): void;
}

export const UserAssignRolesDrawer = ({ user, dismiss }: IUserAssignRolesDrawer) => {
  const { t } = useTranslation();
  const availableRolesQuery = useUserAvailableRolesQuery(user.id);
  const assignClientRoleMutation = useAssignClientRoleToUserMutation();

  const onSubmit = async (roles: string[]) => {
    await Promise.all(
      roles.map(async (roleName) => {
        const role = availableRolesQuery.data!.data.find((r) => r.attributes.name === roleName)!;
        await assignClientRoleMutation.mutateAsync({ userId: user.id, clientId: role.attributes.clientId, roleName });
      })
    );
    dismiss();
  };

  return (
    <>
      <h2>{t('users.assign_roles.title', { username: user.attributes.username })}</h2>
      <div className={'mg-t-nm'} style={{ width: '100%', maxWidth: '686px' }}>
        <QueryGuard query={availableRolesQuery}>
          {{
            success: ({ query }) => {
              return <AddRolesDrawer roles={query.data!.data} onSubmit={onSubmit} dismiss={dismiss} />;
            },
          }}
        </QueryGuard>
      </div>
    </>
  );
};
