import { Client } from '../../../client.model';
import { useTranslation } from 'react-i18next';
import { InputField, ToggleField, useForm } from '@usu/react-form';
import { UsuButton, UsuButtonGroup, UsuIcon } from '@usu/react-components';
import { copyToClipboard } from '../../../../../support/util';
import { FlexRow } from '../../../../../component/common/layout/Flex';
import { useUpdateClientMutation } from '../../../client.mutations';

interface IClientDetailsPanel {
  client: Client;
}

interface IForm {
  enabled: boolean;
  name: string;
  description: string;
}

export const ClientDetailsPanel = ({ client }: IClientDetailsPanel) => {
  const { t } = useTranslation();
  const form = useForm<IForm>({
    initialValues: {
      enabled: client.attributes.enabled,
      name: client.attributes.translatedName,
      description: client.attributes.description,
    },
  });
  const updateClientMutation = useUpdateClientMutation(form);

  const onSubmit = (values: IForm) => {
    const payload = { ...values, type: client.attributes.type };
    updateClientMutation.mutate({ clientUuid: client.id, ...payload });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <FlexRow className={'mg-b-nm'} alignItems={'center'}>
        <InputField disabled label={t('general.uuid')} value={client.id} style={{ width: '100%' }} />
        <UsuButton
          className={'mg-l-sm mg-t-nm'}
          shape={'circle'}
          color={'tertiary'}
          size={'small'}
          onClick={() => copyToClipboard(client.id)}
        >
          <UsuIcon type={'duplicate'} />
        </UsuButton>
      </FlexRow>
      <InputField className={'mg-b-nm'} disabled label={t('general.identifier')} value={client.attributes.name} />
      <ToggleField
        className={'mg-b-nm'}
        label={t('general.enabled')}
        {...form.getInputProps('enabled', { type: 'toggle' })}
      />
      <InputField
        className={'mg-b-nm'}
        label={t('general.name')}
        placeholder={t('general.enter_value')}
        {...form.getInputProps('name')}
      />
      <InputField
        className={'mg-b-nm'}
        label={t('general.description')}
        placeholder={t('general.enter_value')}
        {...form.getInputProps('description')}
      />

      {/* footer */}
      <FlexRow className={'mg-t-nm'} justifyContent={'flex-start'} alignItems={'center'}>
        <UsuButtonGroup>
          <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
            {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
            {t('general.save')}
          </UsuButton>
        </UsuButtonGroup>
      </FlexRow>
    </form>
  );
};
