import { Group } from '../../group.model';
import { GroupHeaderInfo } from './component/GroupHeaderInfo';
import { UsuTab, UsuTabBar, UsuTabPanel } from '@usu/react-components';
import { GroupDetailsPanel } from './component/GroupDetailsPanel';
import { GroupMembersPanel } from './component/GroupMembersPanel';
import { GroupRolesPanel } from './component/GroupRolesPanel';
import { useTranslation } from 'react-i18next';

interface IGroupDetailView {
  group: Group;
}

export const GroupDetailView = ({ group }: IGroupDetailView) => {
  const { t } = useTranslation();
  return (
    <div>
      <GroupHeaderInfo group={group} />
      <UsuTabBar className={'pd-t-nm'}>
        {/* group details */}
        <UsuTab slot='header' name={'details'}>
          {t('general.details')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'details'} className={'pd-t-nm'}>
          <GroupDetailsPanel group={group} />
        </UsuTabPanel>

        {/* group members */}
        <UsuTab slot='header' name={'members'}>
          {t('groups.members')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'members'} className={'pd-t-nm'}>
          <GroupMembersPanel group={group} />
        </UsuTabPanel>

        {/* group roles */}
        <UsuTab slot='header' name={'roles'}>
          {t('general.roles')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'roles'} className={'pd-t-nm'}>
          <GroupRolesPanel group={group} />
        </UsuTabPanel>
      </UsuTabBar>
    </div>
  );
};
