import { SyncAction, UserFederation } from '../../integration.model';
import { FlexRow } from '../../../../component/common/layout/Flex';
import {
  UsuButton,
  UsuDropdownGroupTitle,
  UsuDropdownItem,
  UsuDropdownPanel,
  UsuIcon,
  UsuPopper,
  UsuTab,
  UsuTabBar,
  UsuTabPanel,
  UsuTag,
  UsuTooltip,
  UsuWidgetDashlet,
} from '@usu/react-components';
import { UserFederationDetailsPanel } from './component/UserFederationDetailsPanel';
import { UserFederationConnectionPanel } from './component/UserFederationConnectionPanel';
import { UserFederationSynchronizationPanel } from './component/UserFederationSynchronizationPanel';
import { UserFederationLdapPanel } from './component/UserFederationLdapPanel';
import { UserFederationKerberosPanel } from './component/UserFederationKerberosPanel';
import { useTranslation } from 'react-i18next';
import {
  useDeleteUserFederationMutation,
  useRemoveFederatedUsersMutation,
  useSyncFederatedUsersMutation,
  useUnlinkFederatedUsersMutation,
} from '../../integration.mutation';
import { ConfirmationAction } from '@usu/react-ui';

interface IUserFederationCard {
  userFederation: UserFederation;
}

export const UserFederationCard = ({ userFederation }: IUserFederationCard) => {
  const { t } = useTranslation();
  const { name, enabled, connection } = userFederation.attributes;
  const connected = connection.status === 'connected';
  const syncFederatedUsersMutation = useSyncFederatedUsersMutation();
  const unlinkFederatedUsersMutation = useUnlinkFederatedUsersMutation();
  const removeFederatedUsersMutation = useRemoveFederatedUsersMutation();
  const deleteUserFederationMutation = useDeleteUserFederationMutation();

  const onSync = (action: SyncAction) => {
    syncFederatedUsersMutation.mutate({ federationId: userFederation.id, action });
  };

  const onUnlinkUsers = () => unlinkFederatedUsersMutation.mutate({ federationId: userFederation.id });
  const onRemoveUsers = () => removeFederatedUsersMutation.mutate({ federationId: userFederation.id });

  const onDelete = () => deleteUserFederationMutation.mutate({ federationId: userFederation.id });

  return (
    <UsuWidgetDashlet key={userFederation.id} className={'integration-grid__item'}>
      <FlexRow slot='title' alignItems='center' justifyContent='space-between'>
        <div>
          {name}
          {!enabled && <UsuTag className='mg-l-nm'>{t('general.disabled').toLowerCase()}</UsuTag>}
        </div>
        <FlexRow alignItems='center'>
          <UsuTooltip>
            <UsuIcon
              type={connected ? 'status-online' : 'status-offline'}
              size={'small'}
              style={{ color: connected ? 'green' : 'red' }}
            />
            <span slot='message'>
              {connected ? t('integrations.state.connected') : t('integrations.state.disconnected')}
            </span>
          </UsuTooltip>
          <UsuPopper>
            <UsuButton slot={'anchor'} size='small' color='tertiary' className='mg-l-nm'>
              <UsuIcon type='more' />
            </UsuButton>
            <UsuDropdownPanel slot={'content'}>
              <UsuDropdownGroupTitle>{t('integrations.synchronization')}</UsuDropdownGroupTitle>
              <UsuDropdownItem onUsuClick={() => onSync(SyncAction.Changed)}>
                <UsuIcon slot={'icon-before'} type={'data-exchange'} size={'small'} />
                {t('integrations.dropdown.synchronization.sync_changed_users')}
              </UsuDropdownItem>
              <UsuDropdownItem onUsuClick={() => onSync(SyncAction.All)}>
                <UsuIcon slot={'icon-before'} type={'data-exchange'} size={'small'} />
                {t('integrations.dropdown.synchronization.sync_all_users')}
              </UsuDropdownItem>
              <UsuDropdownGroupTitle className={'mg-t-sm'}>
                {t('integrations.dropdown.imported_users.label')}
              </UsuDropdownGroupTitle>
              <ConfirmationAction
                title={t('integrations.unlink_users.confirm.title')}
                message={t('integrations.unlink_users.confirm.message')}
                onConfirm={onUnlinkUsers}
              >
                {({ open }) => (
                  <UsuDropdownItem onUsuClick={open}>
                    <UsuIcon slot={'icon-before'} type={'unlink'} size={'small'} />
                    {t('integrations.dropdown.imported_users.unlink')}
                  </UsuDropdownItem>
                )}
              </ConfirmationAction>
              <ConfirmationAction
                title={t('integrations.remove_users.confirm.title')}
                message={t('integrations.remove_users.confirm.message')}
                onConfirm={onRemoveUsers}
              >
                {({ open }) => (
                  <UsuDropdownItem onUsuClick={open}>
                    <UsuIcon slot={'icon-before'} type={'users-remove'} size={'small'} />
                    {t('integrations.dropdown.imported_users.remove')}
                  </UsuDropdownItem>
                )}
              </ConfirmationAction>
              <UsuDropdownGroupTitle className={'mg-t-sm'}>{t('general.general')}</UsuDropdownGroupTitle>
              <ConfirmationAction
                title={t('integrations.delete.confirm.title')}
                message={t('integrations.delete.confirm.message')}
                onConfirm={onDelete}
              >
                {({ open }) => (
                  <UsuDropdownItem onUsuClick={open}>
                    <UsuIcon slot={'icon-before'} type={'delete'} size={'small'} />
                    {t('general.delete')}
                  </UsuDropdownItem>
                )}
              </ConfirmationAction>
            </UsuDropdownPanel>
          </UsuPopper>
        </FlexRow>
      </FlexRow>
      <div slot='body'>
        <UsuTabBar className={'pd-t-nm'}>
          {/* user federation details */}
          <UsuTab slot='header' name={'details'}>
            {t('general.details')}
          </UsuTab>
          <UsuTabPanel slot={'panel'} name={'details'} className={'pd-t-nm'}>
            <UserFederationDetailsPanel userFederation={userFederation} />
          </UsuTabPanel>

          {/* user federation connection */}
          <UsuTab slot='header' name={'connection'}>
            {t('integrations.connection')}
          </UsuTab>
          <UsuTabPanel slot={'panel'} name={'connection'} className={'pd-t-nm'}>
            <UserFederationConnectionPanel userFederation={userFederation} />
          </UsuTabPanel>

          {/* user federation synchronization */}
          <UsuTab slot='header' name={'synchronization'}>
            {t('integrations.synchronization')}
          </UsuTab>
          <UsuTabPanel slot={'panel'} name={'synchronization'} className={'pd-t-nm'}>
            <UserFederationSynchronizationPanel userFederation={userFederation} />
          </UsuTabPanel>

          {/* user federation ldap */}
          <UsuTab slot='header' name={'ldap'}>
            {t('integrations.ldap')}
          </UsuTab>
          <UsuTabPanel slot={'panel'} name={'ldap'} className={'pd-t-nm'}>
            <UserFederationLdapPanel userFederation={userFederation} />
          </UsuTabPanel>

          {/* user federation kerberos */}
          <UsuTab slot='header' name={'kerberos'}>
            {t('integrations.kerberos')}
          </UsuTab>
          <UsuTabPanel slot={'panel'} name={'kerberos'} className={'pd-t-nm'}>
            <UserFederationKerberosPanel userFederation={userFederation} />
          </UsuTabPanel>
        </UsuTabBar>
      </div>
    </UsuWidgetDashlet>
  );
};
