import { UsuTab, UsuTabBar, UsuTabPanel } from '@usu/react-components';
import { User } from '../../user.model';
import { UserHeaderInfo } from './component/UserHeaderInfo';
import { UserGroupsPanel } from './component/UserGroupsPanel';
import { UserRolesPanel } from './component/UserRolesPanel';
import { UserDetailsPanel } from './component/UserDetailsPanel';
import { UserAttributesPanel } from './component/UserAttributesPanel';
import { useTranslation } from 'react-i18next';

export interface IUserDetailView {
  user: User;
}

export const UserDetailView = ({ user }: IUserDetailView) => {
  const { t } = useTranslation();
  return (
    <div>
      <UserHeaderInfo user={user} />
      <UsuTabBar className={'pd-t-nm'}>
        {/* user details */}
        <UsuTab slot='header' name={'details'}>
          {t('general.details')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'details'} className={'pd-t-nm'}>
          <UserDetailsPanel user={user} />
        </UsuTabPanel>

        {/* user attributes */}
        <UsuTab slot='header' name={'attributes'}>
          {t('general.attributes')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'attributes'} className={'pd-t-nm'}>
          <UserAttributesPanel user={user} />
        </UsuTabPanel>

        {/* user groups */}
        <UsuTab slot='header' name={'groups'}>
          {t('general.groups')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'groups'} className={'pd-t-nm'}>
          <UserGroupsPanel user={user} />
        </UsuTabPanel>

        {/* user roles */}
        <UsuTab slot='header' name={'roles'}>
          {t('general.roles')}
        </UsuTab>
        <UsuTabPanel slot={'panel'} name={'roles'} className={'pd-t-nm'}>
          <UserRolesPanel user={user} />
        </UsuTabPanel>
      </UsuTabBar>
    </div>
  );
};
