import { useTranslation } from 'react-i18next';
import { InputField, useForm } from '@usu/react-form';
import { useInfo } from '../../realm/realm.queries';
import { UsuButton, UsuButtonGroup, UsuCheckbox, UsuIcon } from '@usu/react-components';
import { useCreateUserMutation, useSetUserPasswordMutation } from '../user.mutations';
import { PasswordPoliciesValidation } from '../../password-policy/component/PasswordPoliciesValidation';
import { FlexRow } from '../../../component/common/layout/Flex';
import { useState } from 'react';
import { HttpResponse } from '../../../support/http';
import { usePasswordValidate } from './ResetPasswordDrawer';

interface IUserFormDrawer {
  dismiss(): void;
}

interface IForm {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  passwordConfirm: string;
  temporary: boolean;
}

export const CreateUserDrawer = ({ dismiss }: IUserFormDrawer) => {
  const { t } = useTranslation();
  const [createAnother, setCreateAnother] = useState(false);
  const { password, passwordConfirm } = usePasswordValidate();
  const infoQuery = useInfo();
  const form = useForm<IForm>({
    validate: {
      email: (value) => {
        if (value?.length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return t('general.invalid_mail');
        }
        return null;
      },
      passwordConfirm: (value, values) => (values.password?.length > 0 ? passwordConfirm(value, values) : null),
      password: (value) => (value?.length > 0 ? password(value) : null),
    },
  });
  const userMutation = useCreateUserMutation(form);
  const userPasswordMutation = useSetUserPasswordMutation();

  const onSubmit = (values: IForm) => {
    const { password, passwordConfirm, temporary, ...rest } = values;
    const payload = {
      ...rest,
      ...(infoQuery.data?.attributes.emailAsUsername ? { username: values.email } : {}),
      enabled: true,
    };
    userMutation.mutate(payload, {
      onSuccess: (res: HttpResponse<{ data: { id: string } }>) => {
        if (password) {
          userPasswordMutation.mutate({ userId: res.data.data.id, password, temporary: true });
        }
        if (createAnother) {
          form.reset();
        } else {
          dismiss();
        }
      },
    });
  };

  return (
    <>
      <h2>{t('users.create.label')}</h2>
      <div className={'mg-t-nm'} style={{ width: '686px' }}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          {/* general */}
          {!infoQuery.data?.attributes.emailAsUsername && (
            <InputField
              className={'mg-b-nm'}
              required
              label={t('general.username')}
              placeholder={t('general.enter_value')}
              {...form.getInputProps('username')}
            />
          )}
          <InputField
            className={'mg-b-nm'}
            // TODO web-component bug, also if `required={false}`, it will be displayed as mandatory
            {...(infoQuery.data?.attributes.emailAsUsername ? { required: true } : {})}
            label={t('general.email')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('email')}
          />
          <InputField
            className={'mg-b-nm'}
            label={t('users.fields.firstname')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('firstname')}
          />
          <InputField
            label={t('users.fields.lastname')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('lastname')}
          />

          {/* temporary password */}
          <h3 className={'mg-t-lg mg-b-nm'}>{t('general.temporary_password')}</h3>
          <InputField
            className={'mg-b-nm'}
            type={'password'}
            label={t('general.password')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('password')}
          />
          <InputField
            className={'mg-b-nm'}
            type={'password'}
            label={t('general.confirm_password')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('passwordConfirm')}
          />
          <PasswordPoliciesValidation key={form.values.password} password={form.values.password} />

          {/* footer */}
          <FlexRow className={'mg-t-nm'} justifyContent={'flex-end'} alignItems={'center'}>
            <UsuCheckbox
              className={'mg-r-sm'}
              size={'small'}
              onUsuChange={(e) => {
                setCreateAnother(e.detail.checked);
              }}
            >
              {t('users.create.another')}
            </UsuCheckbox>
            <UsuButtonGroup>
              <UsuButton onClick={dismiss} color={'secondary'}>
                {t('general.close')}
              </UsuButton>
              <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
                {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
                {t('users.create.label')}
              </UsuButton>
            </UsuButtonGroup>
          </FlexRow>
        </form>
      </div>
    </>
  );
};
