import { Group } from '../group.model';
import { useGroupAvailableRolesQuery } from '../group.queries';
import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { useTranslation } from 'react-i18next';
import { AddRolesDrawer } from '../../role/component/AddRolesDrawer';
import { useAddClientRolesToGroupMutation } from '../group.mutations';

interface IGroupAssignRoleDrawer {
  group: Group;
  dismiss(): void;
}

export const GroupAssignRoleDrawer = ({ group, dismiss }: IGroupAssignRoleDrawer) => {
  const { t } = useTranslation();
  const availableRolesQuery = useGroupAvailableRolesQuery(group.id);
  const addClientRolesMutation = useAddClientRolesToGroupMutation();

  const onSubmit = async (roles: string[]) => {
    await Promise.all(
      roles.map(async (roleName) => {
        const role = availableRolesQuery.data!.data.find((r) => r.attributes.name === roleName)!;
        await addClientRolesMutation.mutateAsync({
          groupId: group.id,
          clientId: role.attributes.clientId,
          roleNames: [roleName],
        });
      })
    );
    dismiss();
  };

  return (
    <>
      <h2>{t('groups.assign_roles.label')}</h2>
      <div className={'mg-t-nm'} style={{ width: '100%', minWidth: '500px', maxWidth: '686px' }}>
        <QueryGuard query={availableRolesQuery}>
          {{
            success: ({ query }) => {
              return <AddRolesDrawer roles={query.data!.data} onSubmit={onSubmit} dismiss={dismiss} />;
            },
          }}
        </QueryGuard>
      </div>
    </>
  );
};
