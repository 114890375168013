import {
  UsuButton,
  UsuDropdownGroupTitle,
  UsuDropdownItem,
  UsuDropdownPanel,
  UsuIcon,
  UsuPopper,
} from '@usu/react-components';
import { FlexCol, FlexRow } from '../../../../../component/common/layout/Flex';
import { Group } from '../../../group.model';
import { useTranslation } from 'react-i18next';
import { useDeleteGroupMutation } from '../../../group.mutations';
import { ConfirmationAction } from '@usu/react-ui';
import { useDrawer } from '../../../../../support/hooks';
import { CreateGroupDrawer } from '../../CreateGroupDrawer';
import { Fragment } from 'react';
import { InfoGrid } from '../../../../../component/common/layout/InfoGrid';
import classNames from 'classnames';

interface IGroupHeaderInfo {
  group: Group;
}

export const GroupHeaderInfo = ({ group }: IGroupHeaderInfo) => {
  const { t } = useTranslation();
  const { name, attributes } = group.attributes;
  const [present, dismiss] = useDrawer(CreateGroupDrawer, { parent: group, dismiss: () => dismiss() });
  const deleteGroupMutation = useDeleteGroupMutation();

  const onDelete = () => deleteGroupMutation.mutate({ groupId: group.id });

  const info = [
    {
      label: t('general.description'),
      value: attributes.description?.[0],
      classes: !attributes.description?.[0] && 'no-data-text',
    },
  ];

  return (
    <FlexRow justifyContent={'space-between'}>
      <FlexCol>
        <FlexRow>
          <h2>{name}</h2>
        </FlexRow>
        <InfoGrid>
          {info.map(({ label, value, classes }) => (
            <Fragment key={label}>
              <InfoGrid.Label>{label}:</InfoGrid.Label>
              <InfoGrid.Value>
                <span className={classNames(classes)}>{value && value.length > 0 ? value : t('general.not_set')}</span>
              </InfoGrid.Value>
            </Fragment>
          ))}
        </InfoGrid>
      </FlexCol>
      <FlexRow>
        <UsuPopper>
          <UsuButton slot={'anchor'} size={'small'} color={'secondary'} shape={'circle'}>
            <UsuIcon type={'more'} />
          </UsuButton>
          <UsuDropdownPanel slot={'content'}>
            <UsuDropdownGroupTitle>{t('general.general')}</UsuDropdownGroupTitle>
            <UsuDropdownItem onUsuClick={present}>
              <UsuIcon slot={'icon-before'} type={'add'} size={'small'} />
              {t('groups.create_subgroup.label')}
            </UsuDropdownItem>
            <ConfirmationAction
              title={t('groups.delete_group.confirm.title')}
              message={t('groups.delete_group.confirm.message')}
              onConfirm={onDelete}
            >
              {({ open }) => (
                <UsuDropdownItem onUsuClick={open}>
                  <UsuIcon slot={'icon-before'} type={'delete'} size={'small'} />
                  {t('groups.delete_group.label')}
                </UsuDropdownItem>
              )}
            </ConfirmationAction>
          </UsuDropdownPanel>
        </UsuPopper>
      </FlexRow>
    </FlexRow>
  );
};
