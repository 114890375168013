import { PropsWithChildren, useMemo } from 'react';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UsuToastProvider } from '@usu/react-components';
import { authConfig, KEYCLOAK_CLIENT_ID } from '../../config/auth';
import { ENV } from '../../support/env';
import { apiQueryClient } from '../../support/api';

interface IAppProvider {}

export const AppProvider = ({ children }: PropsWithChildren<IAppProvider>) => {
  return (
    <UsuToastProvider>
      <QueryClientProvider client={apiQueryClient}>
        <AppAuthProvider>{children}</AppAuthProvider>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </UsuToastProvider>
  );
};

interface IAppAuthProvider {}

function AppAuthProvider({ children }: PropsWithChildren<IAppAuthProvider>) {
  const urlParams = new URLSearchParams(window.location.search);
  const idpHintFromUrl = urlParams.get('idp-hint') || undefined;
  const idpHint = ENV.DEFAULT_IDP_HINT ?? idpHintFromUrl ?? undefined;
  // user is able to overrule idpHint
  const useLocalUser = urlParams.get('local-user') || undefined;

  const authConfigWithEnv = useMemo(
    () =>
      ({
        ...authConfig,
        ...(useLocalUser || !idpHint
          ? {}
          : {
              extraQueryParams: {
                // https://www.keycloak.org/docs/latest/server_admin/index.html#_client_suggested_idp
                kc_idp_hint: idpHint,
              },
            }),
        authority: `${ENV.KEYCLOAK_URL}realms/${ENV.REALM}`,
        clientId: KEYCLOAK_CLIENT_ID,
      } satisfies AuthProviderProps),
    [ENV.KEYCLOAK_URL]
  );

  return <AuthProvider {...authConfigWithEnv}>{children}</AuthProvider>;
}
