import type { TFunction } from 'i18next';
import { ColumnType } from '@usu/react-ui';

export const CLIENT_ROLES_TABLE_COLUMNS = (t: TFunction) =>
  [
    {
      type: ColumnType.String,
      id: 'name',
      accessor: ['attributes', 'name'],
      header: t('general.name'),
    },
    {
      type: ColumnType.String,
      id: 'description',
      accessor: ['attributes', 'description'],
      header: t('general.description'),
    },
    {
      type: ColumnType.Action,
      id: 'actions',
      cellMutator: true,
      header: t('general.actions'),
    },
  ] as const;
