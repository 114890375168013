import { ENV } from '../support/env';

/**
 * All route paths of the application, to be used for `nav()` calls. Each path must
 * be absolute.
 *
 * Note that `./pages.ts` does not use this because of nested route paths, so
 * sadly they're duplicated there.
 */
export const PAGE_IDS = {
  dashboard: 'dashboard',
  groups: 'groups',
  users: 'users',
  clients: 'clients',
  integrations: 'integrations',
  settings: 'settings',
} as const;

/**
 * All API paths used for queries and mutations; see `domain/<…>/<…>.queries.ts` and
 * `domain/<…>/<…>.mutations.ts` for usages
 */
export const API_PATHS = {
  client: {
    list: `api/v1/realms/${ENV.REALM}/clients`, // TODO query parameter ?page=1&size=20&search=
    single: (clientUuid: string) => `api/v1/realms/${ENV.REALM}/clients/${clientUuid}`,
    role: {
      list: (clientUuid: string) => `api/v1/realms/${ENV.REALM}/clients/${clientUuid}/roles/all`, // TODO query parameter ?page=1&size=20&search=
      single: (clientUuid: string, roleName: string) =>
        `api/v1/realms/${ENV.REALM}/clients/${clientUuid}/roles/${roleName}`,
      create: (clientUuid: string) => `api/v1/realms/${ENV.REALM}/clients/${clientUuid}/roles`,
    },
  },
  group: {
    list: `/api/v1/realms/${ENV.REALM}/groups/root`,
    groups: `/api/v1/realms/${ENV.REALM}/groups`,
    subgroup: {
      create: (parentId: string) => `/api/v1/realms/${ENV.REALM}/groups/${parentId}/subgroups`,
    },
    single: (groupId: string) => `/api/v1/realms/${ENV.REALM}/groups/${groupId}`,
    roles: {
      clientRoles: (groupId: string, clientId: string) =>
        `api/v1/realms/${ENV.REALM}/groups/${groupId}/clients/${clientId}/roles`,
      available: (groupId: string) => `api/v1/realms/${ENV.REALM}/groups/${groupId}/clients/all/roles/available`,
      effective: (groupId: string) => `api/v1/realms/${ENV.REALM}/groups/${groupId}/clients/all/roles/effective`, // TODO query parameter ?page=1&size=20&includeInherited=false&search=
    },
    members: {
      list: (groupId: string) => `api/v1/realms/${ENV.REALM}/groups/${groupId}/members`, // TODO GET query parameter ?page=1&size=20&search=
      single: (groupId: string, userId: string) => `api/v1/realms/${ENV.REALM}/groups/${groupId}/members/${userId}`,
    },
  },
  user: {
    list: `/api/v1/realms/${ENV.REALM}/users`, // TODO query parameter ?page=1&size=20&searchText=
    create: `/api/v1/realms/${ENV.REALM}/users`,
    single: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}`,
    enable: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/enable`,
    disable: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/disable`,
    requiredActions: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/required-actions`,
    emailActions: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/email-actions`,
    password: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/password`,
    group: {
      list: (userId: string) => `api/v1/realms/${ENV.REALM}/users/${userId}/groups`,
      available: (userId: string) => `api/v1/realms/${ENV.REALM}/users/${userId}/groups/available`,
    },
    role: {
      list: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/clients/roles/all/effective`, // TODO query parameter ?page=1&size=20&includeInherited=false&search=
      single: (userId: string, clientId: string, roleName: string) =>
        `/api/v1/realms/${ENV.REALM}/users/${userId}/clients/${clientId}/roles/${roleName}`,
      available: (userId: string) => `/api/v1/realms/${ENV.REALM}/users/${userId}/clients/roles/all/available`,
    },
  },
  import: `/api/v1/realms/${ENV.REALM}/import`,
  importLegacy: `/api/v1/realms/${ENV.REALM}/import/legacy`,
  integration: {
    userFederation: {
      list: `/api/v1/realms/${ENV.REALM}/integrations/user-federations`, // TODO query parameter ?page=1&size=20&searchText=
      single: (federationId: string) => `/api/v1/realms/${ENV.REALM}/integrations/user-federations/${federationId}`,
      sync: (federationId: string) => `/api/v1/realms/${ENV.REALM}/integrations/user-federations/${federationId}/sync`,
      unlinkUsers: (federationId: string) =>
        `/api/v1/realms/${ENV.REALM}/integrations/user-federations/${federationId}/unlink-users`,
      removeUsers: (federationId: string) =>
        `/api/v1/realms/${ENV.REALM}/integrations/user-federations/${federationId}/remove-users`,
    },
  },
  realm: {
    info: `/api/v1/realms/${ENV.REALM}/info`,
    infoLight: `/api/v1/realms/${ENV.REALM}/info-light`,
    locales: {
      supported: `/api/v1/realms/${ENV.REALM}/locales`,
      available: `/api/v1/realms/${ENV.REALM}/locales/available`,
    },
    passwordPolicies: `/api/v1/realms/${ENV.REALM}/password-policies`,
    clientSessions: `/api/v1/realms/${ENV.REALM}/statistics/client-sessions`,
  },
} as const;
