import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UsuIcon } from '@usu/react-components';
import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { FlexRow } from '../../../component/common/layout/Flex';
import { isNil } from '../../../support/util';
import { usePasswordPoliciesQuery } from '../password-policy.queries';
import { PasswordPolicy } from '../password-policy.model';
import { getPasswordValidationById, validatePasswordPolicy } from '../password-policy.util';

interface IPasswordPoliciesValidation {
  password: string;
}

export const PasswordPoliciesValidation = ({ password }: IPasswordPoliciesValidation) => {
  const { t } = useTranslation();
  const passwordPolicies = usePasswordPoliciesQuery();

  return (
    <QueryGuard query={passwordPolicies}>
      {{
        success: ({ query }) => {
          const { data } = query;
          return (
            <div className={'mg-t-sm'} style={{ maxWidth: '550px' }}>
              <p>{t('password_policy.validation.title')}</p>
              <FlexRow flexWrap={'wrap'}>
                {data?.map((d) => {
                  return <PolicyItem key={d.id} policy={d} password={password} />;
                })}
              </FlexRow>
            </div>
          );
        },
      }}
    </QueryGuard>
  );
};

interface IPolicyItem extends IPasswordPoliciesValidation {
  policy: PasswordPolicy;
}

const PolicyItem = ({ password, policy }: IPolicyItem) => {
  const { t } = useTranslation();
  const hasValidation = !isNil(getPasswordValidationById(policy));
  const isValid = useMemo(() => validatePasswordPolicy(password, policy), [password, policy]);
  if (!hasValidation) {
    return null;
  }
  const label = t(`general.form.validation.${policy.id}.label`, { value: policy.attributes.value });

  return (
    <FlexRow alignItems={'center'} flex={'0 0 40%'} className={'mg-t-xs txt-sm'}>
      <UsuIcon key={isValid.toString()} type={isValid ? 'checkmark' : 'close'} />
      <span>{label}</span>
    </FlexRow>
  );
};
