import { User } from '../user.model';
import { usePasswordPoliciesQuery } from '../../password-policy/password-policy.queries';
import { InputField, ToggleField, useForm } from '@usu/react-form';
import { checkPasswordValidity } from '../../password-policy/password-policy.util';
import { useTranslation } from 'react-i18next';
import { PasswordPoliciesValidation } from '../../password-policy/component/PasswordPoliciesValidation';
import { FlexRow } from '../../../component/common/layout/Flex';
import { UsuButton, UsuButtonGroup, UsuIcon } from '@usu/react-components';
import { useSetUserPasswordMutation } from '../user.mutations';

interface IResetPasswordDrawer {
  user: User;
  dismiss(): void;
}

interface IForm {
  password: string;
  passwordConfirm: string;
  temporary: boolean;
}

export const usePasswordValidate = () => {
  const { t } = useTranslation();
  const passwordPolicies = usePasswordPoliciesQuery();
  return {
    passwordConfirm: (value: string, values: { password: string }) =>
      value !== values.password ? t('general.confirm_password_match_message') : null,
    password: (value: string) =>
      !checkPasswordValidity(value, passwordPolicies.data || []) ? t('general.password_policies_mismatch') : null,
  };
};

export const ResetPasswordDrawer = ({ user, dismiss }: IResetPasswordDrawer) => {
  const { t } = useTranslation();
  const { password, passwordConfirm } = usePasswordValidate();
  const form = useForm<IForm>({
    initialValues: {
      password: '',
      passwordConfirm: '',
      temporary: true,
    },
    validate: { passwordConfirm, password },
  });
  const userPasswordMutation = useSetUserPasswordMutation();

  const onSubmit = ({ password, temporary }: IForm) => {
    userPasswordMutation.mutate({ userId: user.id, password, temporary }, { onSuccess: dismiss });
  };

  return (
    <>
      <h2>{t('users.dropdown.actions.reset_password')}</h2>
      <div className={'mg-t-nm'} style={{ width: '500px' }}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <InputField
            className={'mg-b-nm'}
            type={'password'}
            label={t('general.password')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('password')}
          />
          <InputField
            className={'mg-b-nm'}
            type={'password'}
            label={t('general.confirm_password')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('passwordConfirm')}
          />
          <PasswordPoliciesValidation key={form.values.password} password={form.values.password} />

          <ToggleField
            className={'mg-t-nm'}
            label={t('general.temporary')}
            {...form.getInputProps('temporary', { type: 'toggle' })}
          />

          {/* footer */}
          <FlexRow className={'mg-t-nm'} justifyContent={'flex-end'} alignItems={'center'}>
            <UsuButtonGroup>
              <UsuButton onClick={dismiss} color={'secondary'}>
                {t('general.close')}
              </UsuButton>
              <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
                {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
                {t('general.save')}
              </UsuButton>
            </UsuButtonGroup>
          </FlexRow>
        </form>
      </div>
    </>
  );
};
