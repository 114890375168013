import { ApiMutationFn, useApiMutation } from '../../support/api';
import { HttpResponse } from '../../support/http';
import { API_PATHS } from '../../config/paths';
import { UseFormReturnType } from '@usu/react-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useUsuToast } from '@usu/react-components';
import { ClientRoleBody, UpdateClientBody } from './client.model';
import { CLIENTS_QUERY_KEY } from './client.queries';

const updateClientMutation: ApiMutationFn<HttpResponse<any>, { clientUuid: string } & UpdateClientBody> = (
  http,
  { clientUuid, ...payload }
) => http.patch<any>(API_PATHS.client.single(clientUuid), payload);

export const useUpdateClientMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(updateClientMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY_KEY] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('clients.update.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('clients.update.error'),
      });
    },
  });
};

const createClientRoleMutation: ApiMutationFn<HttpResponse<any>, { clientUuid: string } & ClientRoleBody> = (
  http,
  { clientUuid, ...payload }
) => http.post<any>(API_PATHS.client.role.create(clientUuid), payload);

export const useCreateClientRoleMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(createClientRoleMutation, {
    onSuccess: (_, { clientUuid }) => {
      queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY_KEY, clientUuid, 'roles'] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('clients.create_role.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('clients.create_role.error'),
      });
    },
  });
};

const deleteClientRoleMutation: ApiMutationFn<HttpResponse<any>, { clientUuid: string; roleName: string }> = (
  http,
  { clientUuid, roleName }
) => http.delete<any>(API_PATHS.client.role.single(clientUuid, roleName));

export const useDeleteClientRoleMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(deleteClientRoleMutation, {
    onSuccess: (_, { clientUuid }) => {
      queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY_KEY, clientUuid, 'roles'] }).catch();
      toast.show({
        status: 'success',
        header: t('clients.delete_role.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('clients.delete_role.error'),
      });
    },
  });
};
