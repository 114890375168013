import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './component/app/App';

import './theme/theme.scss';
import './support/i18n';

// index.html DOM element
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

document.body.className = 'app-more-u-theme';

root.render(<App />);
