import { Client } from '../client.model';
import { UsuListItem, UsuTag } from '@usu/react-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getListItemStyle } from '../../../component/common/misc/MasterDetailView';

interface IClientListItem {
  client: Client;
  selected?: boolean;
  onClick?: () => void;
}

export const ClientListItem = ({ client, selected, onClick }: IClientListItem) => {
  const { t } = useTranslation();
  const { translatedName, name, description, enabled } = client.attributes;
  return (
    <UsuListItem icon={'component'} selected={selected} onClick={onClick} style={getListItemStyle(selected)}>
      <span slot='title'>{translatedName || name}</span>
      {!enabled && (
        <UsuTag slot='info' size='small' color={'yellow'}>
          disabled
        </UsuTag>
      )}
      <span className={classNames(!description && 'no-data-text')}>{description || t('general.no_description')}</span>
    </UsuListItem>
  );
};
