import { ApiEntityData, useApiQuery } from '../../support/api';
import { API_PATHS } from '../../config/paths';
import { ApiGroupData } from './group.model';
import { Role } from '../role/role.model';

export const GROUPS_QUERY_KEY = 'groups';

export const useGroupsQuery = () => {
  return useApiQuery([GROUPS_QUERY_KEY], {
    queryFn: (http, ctx) =>
      http.get<ApiGroupData>(API_PATHS.group.list, { signal: ctx.signal }).then((res) => res.data),
  });
};

export const useGroupMembersQuery = (id: string) => {
  return useApiQuery([GROUPS_QUERY_KEY, id, 'members'], {
    queryFn: (http, ctx) =>
      http.get<ApiEntityData<any>>(API_PATHS.group.members.list(id), { signal: ctx.signal }).then((res) => res.data),
  });
};

export const useGroupEffectiveRolesQuery = (id: string) => {
  return useApiQuery([GROUPS_QUERY_KEY, id, 'roles', 'effective'], {
    queryFn: (http, ctx) =>
      http
        .get<ApiEntityData<Role[]>>(API_PATHS.group.roles.effective(id), { signal: ctx.signal })
        .then((res) => res.data),
  });
};

export const useGroupAvailableRolesQuery = (id: string) => {
  return useApiQuery([GROUPS_QUERY_KEY, id, 'roles', 'available'], {
    queryFn: (http, ctx) =>
      http
        .get<ApiEntityData<Role[]>>(API_PATHS.group.roles.available(id), { signal: ctx.signal })
        .then((res) => res.data),
  });
};
