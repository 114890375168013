import { Client } from '../client.model';
import { InputField, useForm } from '@usu/react-form';
import { useTranslation } from 'react-i18next';
import { FlexRow } from '../../../component/common/layout/Flex';
import { UsuButton, UsuButtonGroup, UsuIcon } from '@usu/react-components';
import { useCreateClientRoleMutation } from '../client.mutations';

interface IClientCreateRoleDrawer {
  client: Client;
  dismiss(): void;
}

interface IForm {
  name: string;
  description?: string;
}

export const ClientCreateRoleDrawer = ({ client, dismiss }: IClientCreateRoleDrawer) => {
  const { t } = useTranslation();
  const form = useForm<IForm>();
  const createRoleMutation = useCreateClientRoleMutation(form);

  const onSubmit = (values: IForm) => {
    createRoleMutation.mutate(
      { clientUuid: client.id, ...values },
      {
        onSuccess: dismiss,
      }
    );
  };

  return (
    <>
      <h2>{t('clients.create_role.label')}</h2>
      <div className={'mg-t-nm'} style={{ width: '500px' }}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <InputField
            className={'mg-b-nm'}
            required
            label={t('general.name')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('name')}
          />
          <InputField
            className={'mg-b-nm'}
            label={t('general.description')}
            placeholder={t('general.enter_value')}
            {...form.getInputProps('description')}
          />

          {/* footer */}
          <FlexRow className={'mg-t-nm'} justifyContent={'flex-start'} alignItems={'center'}>
            <UsuButtonGroup>
              <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
                {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
                {t('general.save')}
              </UsuButton>
            </UsuButtonGroup>
          </FlexRow>
        </form>
      </div>
    </>
  );
};
