import { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react';
import classNames, { Argument as CSSClass } from 'classnames';

interface IFlexBox {
  className?: CSSClass;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
  inline?: boolean;
  reverse?: boolean;
  alignItems?: CSSProperties['alignItems'];
  alignSelf?: CSSProperties['alignSelf'];
  justifyContent?: CSSProperties['justifyContent'];
  justifySelf?: CSSProperties['justifySelf'];
  flexWrap?: CSSProperties['flexWrap'];
  flex?: CSSProperties['flex'];
  slot?: string;
  title?: string;
}

interface IFlexRow extends IFlexBox {}

export function FlexRow(props: PropsWithChildren<IFlexRow>) {
  const { children, className, style, onClick, inline, reverse, slot, title, ...flexStyle } = props;

  return (
    <div
      data-flex={'row'}
      className={classNames(className)}
      data-inline={inline}
      data-reverse={reverse}
      slot={slot}
      title={title}
      style={{ ...flexStyle, ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

interface IFlexCol extends IFlexBox {}

export function FlexCol(props: PropsWithChildren<IFlexCol>) {
  const { children, className, onClick, style, inline, reverse, slot, title, ...flexStyle } = props;

  return (
    <div
      data-flex={'col'}
      className={classNames(className)}
      data-inline={inline}
      data-reverse={reverse}
      slot={slot}
      title={title}
      style={{ ...flexStyle, ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export function FlexSpacer(props: Record<string, never>) {
  return <div style={{ flexGrow: 1 }} />;
}
