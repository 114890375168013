import { CSSProperties, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classNames, { Argument as CSSClass } from 'classnames';
import { UsuBreadcrumb, UsuBreadcrumbList, UsuContentHeader } from '@usu/react-components';
import { isEmpty } from '../../../support/util';

export type Breadcrumb = {
  id: string;
  label: string;
  onClick?: () => void;
};

export interface IPage {
  heading: string;
  transparent?: boolean;
  breadcrumbs?: Breadcrumb[];
  className?: CSSClass;
  style?: CSSProperties;
}

export const Page = ({ children, breadcrumbs, heading, className, style, ...rest }: PropsWithChildren<IPage>) => {
  const { t } = useTranslation();

  return (
    <>
      <div slot={'content'} style={style} className={classNames(className)}>
        <UsuContentHeader heading={heading ? t(heading) : undefined} transparent={false} {...rest}>
          {!isEmpty(breadcrumbs) && (
            <UsuBreadcrumbList slot={'breadcrumb'} maxWidth={'150px'}>
              {breadcrumbs!.map(({ id, label, onClick }) => (
                <UsuBreadcrumb key={id} onUsuBreadcrumbClick={onClick}>
                  {label}
                </UsuBreadcrumb>
              ))}
            </UsuBreadcrumbList>
          )}
        </UsuContentHeader>
        {children}
      </div>
    </>
  );
};
