import { ComponentClass, FunctionComponent, useCallback, useMemo } from 'react';
import { useUsuDrawer } from '@usu/react-components';
import { DrawerOptions as UsuDrawerOptions, OverlayEventDetail } from '@usu/web-components';

interface HookOverlayOptions {
  hideCloseBtn?: boolean;
  onDidDismiss?: (event: CustomEvent<OverlayEventDetail>) => void;
  onDidPresent?: (event: CustomEvent<OverlayEventDetail>) => void;
  onWillDismiss?: (event: CustomEvent<OverlayEventDetail>) => void;
  onWillPresent?: (event: CustomEvent<OverlayEventDetail>) => void;
}

export type DrawerComponent<Props> = FunctionComponent<Props> | ComponentClass<Props, any>;

export type DrawerOptions = Omit<UsuDrawerOptions, 'component' | 'componentProps'> & HookOverlayOptions;

export type DrawerControlOptions = Pick<
  DrawerOptions,
  'showBlanket' | 'escKeyDismiss' | 'outsideClickDismiss' | 'navigationDismiss' | 'hideCloseBtn'
>;

/**
 * Wrapper for `useUsuDrawer` to enable prop type inference for the passed `component`, so
 * that writing `useDrawer(MyComponent, { … })` gives type hints for the props object.
 */
export const useDrawer = <Props>(component: DrawerComponent<Props>, props: Props, options?: DrawerOptions) => {
  const [present, dismiss] = useUsuDrawer(component, props);
  const presentWithOpts = useCallback(() => present(options), [present, options]);
  return useMemo(() => [presentWithOpts, dismiss], [presentWithOpts, dismiss]);
};
