import { useUsersQuery } from '../user.queries';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../../support/hooks';
import { CreateUserDrawer } from './CreateUserDrawer';
import { MasterDetailView } from '../../../component/common/misc/MasterDetailView';
import { FlexCol, FlexRow } from '../../../component/common/layout/Flex';
import { UsuButton, UsuIcon, UsuPaginator, UsuSearchInput } from '@usu/react-components';
import { UserListItem } from './UserListItem';
import { UserDetailView } from './detail-view/UserDetailView';
import { isNil } from '../../../support/util';

export const UsersList = () => {
  const { t } = useTranslation();
  const [present, dismiss] = useDrawer(CreateUserDrawer, { dismiss: () => dismiss() });
  const [usersData, params, goToPage, updateParams] = useUsersQuery();

  return (
    <MasterDetailView
      data={usersData.isLoading || usersData.isRefetching || isNil(usersData.data) ? [] : usersData.data.data}
      toolbar={
        <FlexCol>
          <FlexRow className={'mg-b-nm'}>
            <UsuButton size={'small'} onClick={present}>
              <UsuIcon slot={'icon-before'} type={'add'} />
              {t('users.create.label')}
            </UsuButton>
          </FlexRow>
          <UsuSearchInput
            className={'mg-b-nm'}
            placeholder={t('users.search.placeholder')}
            value={params.search}
            onUsuSearch={(ev) => {
              const search = ((ev.detail.value as string) || '').trim();
              if (search && search.length > 0) {
                updateParams({ search });
              }
            }}
            onUsuInput={(ev) => {
              if (params.search && ev.detail.value === '') {
                updateParams({ search: undefined });
              }
            }}
          />
        </FlexCol>
      }
      renderItem={({ item: user, isSelected, setSelected }) => (
        <UserListItem key={user.id} user={user} selected={isSelected} onClick={() => setSelected(user)} />
      )}
      renderDetail={(selected) => <UserDetailView key={selected.id} user={selected} />}
      footer={
        <FlexRow className={'pd-t-nm'} justifyContent={'center'}>
          <UsuPaginator
            min={1}
            max={params.totalPages || 1}
            current={params.page || 1}
            onUsuChange={(e) => goToPage(e.detail.currentPage)}
          />
        </FlexRow>
      }
    />
  );
};
