import { ApiMutationFn, useApiMutation } from '../../support/api';
import { HttpResponse } from '../../support/http';
import { API_PATHS } from '../../config/paths';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useUsuToast } from '@usu/react-components';
import { UseFormReturnType } from '@usu/react-form';
import { GroupRequestBody, GroupType } from './group.model';
import { GROUPS_QUERY_KEY } from './group.queries';
import { USERS_QUERY_KEY } from '../user/user.queries';

const createGroupMutation: ApiMutationFn<HttpResponse<any>, GroupRequestBody> = (http, payload) => {
  const queryString = new URLSearchParams({ groupType: GroupType.Group }).toString();
  return http.post<any>(`${API_PATHS.group.groups}?${queryString}`, payload);
};

export const useCreateGroupMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(createGroupMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('groups.create.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('groups.create.error'),
      });
    },
  });
};

const createSubgroupMutation: ApiMutationFn<HttpResponse<any>, { parentId: string } & GroupRequestBody> = (
  http,
  { parentId, ...payload }
) => {
  return http.post<any>(API_PATHS.group.subgroup.create(parentId), payload);
};

export const useCreateSubgroupMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(createSubgroupMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('groups.create.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('groups.create.error'),
      });
    },
  });
};

const updateGroupMutation: ApiMutationFn<HttpResponse<any>, { groupId: string } & GroupRequestBody> = (
  http,
  { groupId, ...payload }
) => http.patch<any>(API_PATHS.group.single(groupId), payload);

export const useUpdateGroupMutation = (form: UseFormReturnType<any>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(updateGroupMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY] }).catch();
      form.resetIsSubmitting();
      form.resetDirty();
      toast.show({
        status: 'success',
        header: t('groups.update.success'),
      });
    },
    onError: () => {
      form.resetIsSubmitting();
      toast.show({
        status: 'error',
        header: t('groups.update.error'),
      });
    },
  });
};

const deleteGroupMutation: ApiMutationFn<HttpResponse<any>, { groupId: string }> = (http, { groupId }) =>
  http.delete<any>(API_PATHS.group.single(groupId));

export const useDeleteGroupMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(deleteGroupMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY] }).catch();
      toast.show({
        status: 'success',
        header: t('groups.delete_group.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('groups.delete_group.error'),
      });
    },
  });
};

const addUserToGroupMutation: ApiMutationFn<HttpResponse<any>, { groupId: string; userId: string }> = (
  http,
  { groupId, userId }
) => http.post<any>(API_PATHS.group.members.single(groupId, userId));

export const useAddUserToGroupMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(addUserToGroupMutation, {
    onSuccess: (_, { groupId, userId }) => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY, groupId, 'members'] }).catch();
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY, userId, 'groups'] }).catch();
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY, userId, 'available-groups'] }).catch();
      toast.show({
        status: 'success',
        header: t('users.join_group.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.join_group.error'),
      });
    },
  });
};

const removeUserFromGroupMutation: ApiMutationFn<HttpResponse<any>, { groupId: string; userId: string }> = (
  http,
  { groupId, userId }
) => http.delete<any>(API_PATHS.group.members.single(groupId, userId));

export const useRemoveUserFromGroupMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(removeUserFromGroupMutation, {
    onSuccess: (_, { groupId, userId }) => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY, groupId, 'members'] }).catch();
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY, userId, 'groups'] }).catch();
      toast.show({
        status: 'success',
        header: t('users.remove_group.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('users.remove_group.error'),
      });
    },
  });
};

const getRoleNamesQueryParams = (roleNames: string[]) => {
  const params = new URLSearchParams();
  for (const roleName of roleNames) {
    params.append('roleNames', roleName);
  }
  return params.toString();
};

const addClientRolesToGroupMutation: ApiMutationFn<
  HttpResponse<any>,
  { groupId: string; clientId: string; roleNames: string[] }
> = (http, { groupId, clientId, roleNames }) => {
  const queryString = getRoleNamesQueryParams(roleNames);
  return http.post<any>(`${API_PATHS.group.roles.clientRoles(groupId, clientId)}?${queryString}`);
};

export const useAddClientRolesToGroupMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(addClientRolesToGroupMutation, {
    onSuccess: (_, { groupId }) => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY, groupId, 'roles'] }).catch();
      toast.show({
        status: 'success',
        header: t('groups.assign_roles.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('groups.assign_roles.error'),
      });
    },
  });
};

const removeClientRolesFromGroupMutation: ApiMutationFn<
  HttpResponse<any>,
  { groupId: string; clientId: string; roleNames: string[] }
> = (http, { groupId, clientId, roleNames }) => {
  const queryString = getRoleNamesQueryParams(roleNames);
  return http.delete<any>(`${API_PATHS.group.roles.clientRoles(groupId, clientId)}?${queryString}`);
};

export const useRemoveClientRolesFromGroupMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useUsuToast();

  return useApiMutation(removeClientRolesFromGroupMutation, {
    onSuccess: (_, { groupId }) => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY, groupId, 'roles'] }).catch();
      toast.show({
        status: 'success',
        header: t('groups.unassign_role.success'),
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        header: t('groups.unassign_role.error'),
      });
    },
  });
};
