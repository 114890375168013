import { PageProps } from '../../config/routes';
import { Page } from '../common/layout/Page';
import { RealmSettings } from '../../domain/realm/component/RealmSettings';
import { useDrawer } from '../../support/hooks';
import { ImportDrawer } from '../../domain/import/component/ImportDrawer';
import { UsuButton, UsuIcon } from '@usu/react-components';
import { FlexRow } from '../common/layout/Flex';
import { useTranslation } from 'react-i18next';

export const Settings = ({ page }: PageProps) => {
  const { t } = useTranslation();
  const [open, dismiss] = useDrawer(ImportDrawer, { dismiss: () => dismiss() });

  return (
    <Page heading={page.title}>
      <FlexRow justifyContent={'flex-end'}>
        <UsuButton color={'secondary'} size={'small'} onClick={open}>
          <UsuIcon slot={'icon-before'} type={'upload'} />
          {t('general.import')}
        </UsuButton>
      </FlexRow>
      <RealmSettings />
    </Page>
  );
};
