import { useEffect, useMemo } from 'react';
import { TreeNode } from './TreeNode';
import { TreeController, useTree } from './useTree';

function getFlatValues(data: TreeNodeData[]): string[] {
  return data.reduce<string[]>((acc, item) => {
    acc.push(item.value);
    if (item.children) {
      acc.push(...getFlatValues(item.children));
    }
    return acc;
  }, []);
}
export interface TreeNodeData {
  label: React.ReactNode;
  value: string;
  nodeProps?: Record<string, any>;
  expand?: boolean;
  children?: TreeNodeData[];
}

export interface RenderTreeNodePayload {
  /** Node level in the tree */
  level: number;

  /** `true` if the node is expanded, applicable only for nodes with `children` */
  expanded: boolean;

  /** `true` if the node has non-empty `children` array */
  hasChildren: boolean;

  /** Node data from the `data` prop of `Tree` */
  node: TreeNodeData;

  /** Tree controller instance, return value of `useTree` hook */
  tree: TreeController;

  /** Props to spread into the root node element */
  elementProps: {
    onClick: (event: React.MouseEvent) => void;
    'data-value': string;
    'data-hovered': boolean | undefined;
  };
}

export type RenderNode = (payload: RenderTreeNodePayload) => React.ReactNode;

interface ITree {
  /** Data used to render nodes */
  data: TreeNodeData[];

  /** Determines whether tree node with children should be expanded on click, `true` by default */
  expandOnClick?: boolean;

  /** Determines whether tree node with children should be expanded on space key press, `true` by default */
  expandOnSpace?: boolean;

  /** use-tree hook instance that can be used to manipulate component state */
  tree?: TreeController;

  /** A function to render tree node label */
  renderNode?: RenderNode;
}

export const Tree = ({ data, tree, expandOnClick = true, expandOnSpace = true, renderNode }: ITree) => {
  const defaultController = useTree();
  const controller = tree || defaultController;

  const flatValues = useMemo(() => getFlatValues(data), [data]);

  useEffect(() => {
    controller.initialize(data);
  }, [data]);

  const nodes = data.map((node, index) => (
    <TreeNode
      key={node.value}
      node={node}
      rootIndex={index}
      expandOnClick={expandOnClick}
      controller={controller}
      renderNode={renderNode}
      flatValues={flatValues}
      expandOnSpace={expandOnSpace}
    />
  ));

  return (
    <ul role='tree' data-tree-root='true'>
      {nodes}
    </ul>
  );
};
