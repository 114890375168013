import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

interface ITimeout {
  /**
   * The timeout's duration in miliseconds (defaults to 10,000ms).
   */
  duration?: number;
  /**
   * Content to show after expiration (defaults to `null`).
   */
  whenExpired?: ReactNode | null;
}

/**
 * A component that displays its `children` while a timer is running.
 * After expiration, optional replacement content is shown instead.
 */
export function Timeout(props: PropsWithChildren<ITimeout>) {
  const { duration = 10_000, whenExpired, children } = props;

  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setExpired(true), duration);
    return () => clearTimeout(timer);
  }, [duration]);

  return <>{(expired ? whenExpired : children) || null}</>;
}
