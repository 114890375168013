import { AutocompleteField, useForm } from '@usu/react-form';
import { useTranslation } from 'react-i18next';
import { UsuButton, UsuIcon } from '@usu/react-components';
import { useAvailableLocalesQuery, useSupportedLocalesQuery } from '../realm.queries';
import { QueryGuard } from '../../../component/common/api/QueryGuard';
import { Locale } from '../realm.model';
import { useUpdateSupportedLocalesMutation } from '../realm.mutations';

export const RealmSettings = () => {
  const supportedLocalesQuery = useSupportedLocalesQuery();
  const availableLocalesQuery = useAvailableLocalesQuery();

  // TODO enhance QueryGuard to check a list of queries
  return (
    <QueryGuard query={supportedLocalesQuery}>
      {{
        success: ({ query: supportedQuery }) => {
          return <SettingsForm supported={supportedQuery.data!} available={availableLocalesQuery.data!} />;
        },
      }}
    </QueryGuard>
  );
};

interface ISettingsForm {
  supported?: Locale[];
  available: Locale[];
}

interface IForm {
  supportedLocales: { label: string; value: string }[];
}

const SettingsForm = ({ supported, available }: ISettingsForm) => {
  const { t } = useTranslation();
  const form = useForm<IForm>({
    initialValues: {
      supportedLocales: supported?.map(({ label, code }) => ({ label, value: code })) || [],
    },
  });
  const updateSupportedLocalesMutation = useUpdateSupportedLocalesMutation(form);

  const onSubmit = (values: IForm) => {
    updateSupportedLocalesMutation.mutate(values.supportedLocales?.map(({ value }) => value));
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <AutocompleteField
        label={t('realm.supported_locales.label')}
        help={t('realm.supported_locales.help')}
        multiple
        options={available.map(({ label, code }) => ({ label, value: code }))}
        {...form.getInputProps('supportedLocales')}
      />
      <UsuButton className={'mg-t-nm'} type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
        {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
        {t('general.save')}
      </UsuButton>
    </form>
  );
};
