import { IUserDetailView } from '../UserDetailView';
import { useTranslation } from 'react-i18next';
import { InputField, useForm } from '@usu/react-form';
import { FlexRow } from '../../../../../component/common/layout/Flex';
import { UsuButton, UsuButtonGroup, UsuIcon } from '@usu/react-components';
import { useInfo } from '../../../../realm/realm.queries';
import { useUpdateUserMutation } from '../../../user.mutations';
import { copyToClipboard } from '../../../../../support/util';

interface IUserDetailsPanel extends IUserDetailView {}

interface IForm {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
}

export const UserDetailsPanel = ({ user }: IUserDetailsPanel) => {
  const { t } = useTranslation();
  const infoQuery = useInfo();
  const form = useForm<IForm>({
    initialValues: {
      username: user.attributes.username,
      email: user.attributes.email,
      firstname: user.attributes.firstname || '',
      lastname: user.attributes.lastname || '',
    },
  });
  const userMutation = useUpdateUserMutation(form);

  const onSubmit = (values: IForm) => {
    const payload = {
      ...values,
      ...(infoQuery.data?.attributes.emailAsUsername ? { username: values.email } : {}),
      attributes: user.attributes.attributes || {},
    };
    userMutation.mutate({ userId: user.id, ...payload });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <FlexRow className={'mg-b-nm'} alignItems={'center'}>
        <InputField disabled label={t('general.uuid')} value={user.id} style={{ width: '100%' }} />
        <UsuButton
          className={'mg-l-sm mg-t-nm'}
          shape={'circle'}
          color={'tertiary'}
          size={'small'}
          onClick={() => copyToClipboard(user.id)}
        >
          <UsuIcon type={'duplicate'} />
        </UsuButton>
      </FlexRow>
      <InputField
        className={'mg-b-nm'}
        required
        disabled={infoQuery.data?.attributes.emailAsUsername}
        label={t('general.username')}
        placeholder={t('general.enter_value')}
        {...form.getInputProps('username')}
      />
      <InputField
        className={'mg-b-nm'}
        // TODO web-component bug, also if `required={false}`, it will be displayed as mandatory
        {...(infoQuery.data?.attributes.emailAsUsername ? { required: true } : {})}
        label={t('general.email')}
        placeholder={t('general.enter_value')}
        {...form.getInputProps('email')}
      />
      <InputField
        className={'mg-b-nm'}
        label={t('users.fields.firstname')}
        placeholder={t('general.enter_value')}
        {...form.getInputProps('firstname')}
      />
      <InputField
        label={t('users.fields.lastname')}
        placeholder={t('general.enter_value')}
        {...form.getInputProps('lastname')}
      />

      {/* footer */}
      <FlexRow className={'mg-t-nm'} justifyContent={'flex-start'} alignItems={'center'}>
        <UsuButtonGroup>
          <UsuButton type={'submit'} disabled={form.isSubmitting || !form.isDirty()}>
            {form.isSubmitting && <UsuIcon slot='icon-before' size='small' className='icon-spin' type='refresh' />}
            {t('general.save')}
          </UsuButton>
        </UsuButtonGroup>
      </FlexRow>
    </form>
  );
};
