import { useAuth } from 'oidc-react';
import { API_PATHS } from '../../config/paths';
import { AdditionalApiQueryOptions, useApiQuery } from '../../support/api';
import { AccountData, ApiAccountData } from './account.model';

export const ACCOUNT_QUERY_KEY = 'account';

export function useAccountQuery({ enabled }: AdditionalApiQueryOptions<AccountData> = { enabled: true }) {
  const auth = useAuth();
  return useApiQuery([ACCOUNT_QUERY_KEY], {
    queryFn: (http, ctx) =>
      http
        .get<ApiAccountData>(API_PATHS.user.single(auth.userData!.profile.sub), { signal: ctx.signal })
        .then((res) => res.data.data),
    enabled,
  });
}
