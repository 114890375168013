import {
  QueryClient,
  QueryFunctionContext,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { QueryKey } from '@tanstack/query-core';
import { UseMutationOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query/src/types';
import { Axios } from 'axios';
import { deepEqual } from 'fast-equals';
import { useAuthenticatedHttpClient } from './http';
import { useCallback, useMemo, useState } from 'react';
import { isFn, last } from './util';

/**
 * Primary `QueryClient` (@tanstack/react-query) instance for the application
 */
export const apiQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      // Default staleTime is zero, it is aimed to keep your data as up-to-date as necessary.
      // It is not needed for the Account-UI. Where only the user himself, can change sth.
      // We ignore changes via Admin-UI, that should be a real edgy case.
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      // will retry failing requests 2 times before showing the final error thrown by the function
      retry: 2,
      structuralSharing: (oldData, newData) => {
        return deepEqual(oldData, newData) ? oldData : newData;
      },
    },
  },
});

/**
 * Wrapper around `useQuery` (@tanstack/react-query) to inject the application's HTTP client into query functions
 */
export function useApiQuery<
  TQueryKey extends QueryKey = QueryKey,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TPageParam = any
>(
  queryKey: TQueryKey,
  options: ApiQueryOptions<TQueryFnData, TError, TData, TPageParam, TQueryKey>
): UseQueryResult<TData, TError> {
  const http = useAuthenticatedHttpClient();

  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    // suppressing the following lint because this is just a helper function
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: (context) => options.queryFn(http, context),
  });
}

/**
 * Wrapper around `useQuery` (@tanstack/react-query) specialized for paginated API responses.
 *
 * See the `ApiPage<T>` interface for more information.
 */
export function usePagedApiQuery<
  TQueryKey extends QueryKey = QueryKey,
  TQueryParams extends IApiPageState = IApiPageState,
  TQueryFnData extends ApiPage = ApiPage<unknown>,
  TError = unknown,
  TData = TQueryFnData,
  TPageParam = any
>(
  queryKey: (_: TQueryParams) => TQueryKey,
  options: ApiQueryOptions<TQueryFnData, TError, TData, TPageParam, TQueryKey>
): UsePagedApiQueryReturn<TData, TError, TQueryParams> {
  const queryClient = useQueryClient();
  const [state, setState] = useState<TQueryParams>(emptyPaginationState as TQueryParams);

  const key = useMemo(() => queryKey(state), [state]);
  const query = useApiQuery<TQueryKey, TQueryFnData, TError, TData, TPageParam>(key, options);

  const updateParams = useCallback(
    (params: Partial<TQueryParams> | ((current: TQueryParams) => TQueryParams)) =>
      setState(isFn(params) ? params : (state) => ({ ...state, ...params })),
    []
  );

  const goToPage = useCallback(
    (page: number) => {
      queryClient.cancelQueries({ queryKey: key }).catch();
      updateParams({ page } as Partial<TQueryParams>);
    },
    [key]
  );

  const totalPages = (query.data as ApiPage<unknown>)?.page.totalPages || 1;

  const derivedState = useMemo(() => {
    const queryKeyParams = getQueryParamsFromKey<TQueryParams>(key);
    return { ...queryKeyParams, ...state, totalPages };
  }, [state, key, totalPages]);

  return [query, derivedState, goToPage, updateParams];
}

/**
 * Utility function for paginated API queries to ensure proper typings
 */
export function normalizePagedQueryParams<P extends IApiPageState = IApiPageState>(
  params?: Partial<P>,
  defaultParams?: Partial<P>
): P {
  const { page, size, ...rest } = params || {};

  return {
    page: page || 1,
    size: size || 10,
    ...defaultParams,
    ...rest,
  } as P;
}

/**
 * Returns request query parameters for a given query key; this simply returns the last array
 * element, meaning the convention is to place a parameter object as the **last** element in
 * the key array.
 */
export function getQueryParamsFromKey<P extends IApiPageState = IApiPageState>(queryKey: any): P {
  return last(queryKey) as P;
}

/**
 * Wrapper around `useMutation` (@tanstack/react-query) to inject the application's HTTP client into mutation functions
 */
export function useApiMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: ApiMutationFn<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
): UseMutationResult<TData, TError, TVariables, TContext> {
  const http = useAuthenticatedHttpClient();

  return useMutation((variables) => mutationFn(http, variables), options);
}

/**
 * Type overwrite for `useApiQuery` argument
 */
export type ApiQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TPageParam = any,
  TQueryKey extends QueryKey = QueryKey
> = {
  queryFn: ApiQueryFn<TQueryFnData, TQueryKey, TPageParam>;
  // initialData?: () => TData | undefined;
} & StrippedApiQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

export type StrippedApiQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn' | 'queryKey'>;

export type AdditionalApiQueryOptions<TData = unknown> = {
  enabled?: boolean;
  refetchInterval?: number | false | ((data: TData | undefined) => number | false);
  staleTime?: number;
  initialData?: TData;
  onSuccess?: (_: TData) => any;
  // ...
};

/**
 * Type overwrite for `useApiQuery`
 */
export type ApiQueryFn<T = unknown, TQueryKey extends QueryKey = QueryKey, TPageParam = any> = (
  http: Axios,
  context: QueryFunctionContext<TQueryKey, TPageParam>
) => Promise<T>;

/**
 * Common base shape for the state of paginated API queries
 */
export interface IApiPageState {
  page: null | number;
  size: null | number;
  totalElements: null | number;
  totalPages: null | number;
  search?: string;
}

/**
 * Common base shape for the state of paginated API queries
 */
export interface IApiPageStateWTF {
  number: null | number;
  size: null | number;
  totalElements: null | number;
  totalPages: null | number;
  search?: string;
}

const emptyPaginationState = {
  page: null,
  size: null,
  totalElements: null,
  totalPages: null,
} satisfies IApiPageState;

export type PagedApiGoToPageFn = (page: number) => void;
export type PagedApiUpdateParamsFn<TQueryParams extends IApiPageState = IApiPageState> = (
  params: Partial<TQueryParams> | ((current: TQueryParams) => TQueryParams)
) => void;

export type UsePagedApiQueryReturn<TData, TError, TQueryParams extends IApiPageState> = [
  UseQueryResult<TData, TError>,
  TQueryParams,
  PagedApiGoToPageFn,
  PagedApiUpdateParamsFn<TQueryParams>
];

/**
 * Common API data shape for paginated responses
 */
export interface ApiPage<T = unknown> {
  data: T[];
  page: IApiPageStateWTF;
}

/**
 * Type overwrite for `useApiMutation`
 */
export type ApiMutationFn<TData = unknown, TVariables = unknown> = (
  http: Axios,
  variables: TVariables
) => Promise<TData>;

/**
 * Common API data shape for "detail"-type responses
 */
export interface ApiEntityData<Content> {
  data: Content;
}
